import { API_URL } from './constants';
import { ApiImagesListResponse } from './types/images';

export async function apiImagesList(
  userId: number,
  token: string,
): Promise<ApiImagesListResponse | null> {
  const url = new URL(`${API_URL}/admin/images/list?userId=${userId}`).href;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status === 200) {
    return (await response.json()) as ApiImagesListResponse;
  }
  return null;
}
