import { API_URL } from './constants';
import { AuthLoginRequestBody, AuthLoginResponse } from './types/auth';

export async function apiAuthLogin(
  body: AuthLoginRequestBody,
): Promise<AuthLoginResponse | null> {
  const url = new URL(`${API_URL}/admin/auth/login`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  if (response.status === 201) {
    return (await response.json()) as AuthLoginResponse;
  }
  return null;
}
