/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react';
import { Card, Checkbox, Input, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { ApiUsersListResponseUser } from '../../services/api/types/users';
import { apiUsersList } from '../../services/api/users';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';
import { useNavigate } from 'react-router';
import { RouterPaths } from '../../router/paths';
import { ImageEntity } from '../../services/api/types/images';
import Image from '../../components/Image';
import { useSearchParams } from 'react-router-dom';
import { debounce } from '../../helpers';

interface DataType extends ApiUsersListResponseUser {
  key: string;
}

const PER_PAGE_LIMIT = 10;

function UsersPage() {
  const [params, setSearchParams] = useSearchParams({ pageOffset: '0' });
  const pageOffset = params.get('pageOffset');
  const [paging, setPaging] = React.useState<{ offset: number; limit: number }>(
    {
      offset: pageOffset ? parseInt(pageOffset) : 0,
      limit: PER_PAGE_LIMIT,
    },
  );
  const [dataSource, setDataSource] = React.useState<
    [DataType[], number] | null
  >(null);
  const { token } = useSelector(selectUserState);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [onlyIntegrated, setOnlyIntegrated] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (token) {
      setLoading(true);
      (async () => {
        const response = await apiUsersList(
          {
            offset: paging.offset,
            limit: paging.limit,
            search: searchValue?.length > 2 ? searchValue : undefined,
            showOnlyIntegrated: onlyIntegrated,
          },
          token,
        );
        if (response) {
          if (dataSource) {
            setDataSource([
              [
                ...dataSource[0],
                ...response.users.map((usr) => ({
                  ...usr,
                  key: usr.id.toString(),
                })),
              ],
              response.total,
            ]);
          } else {
            setDataSource([
              response.users.map((usr) => ({ ...usr, key: usr.id.toString() })),
              response.total,
            ]);
          }
          setDataSource([
            response.users.map((usr) => ({ ...usr, key: usr.id.toString() })),
            response.total,
          ]);
        } else {
          setDataSource([[], 0]);
        }
        setLoading(false);
      })();
    }
  }, [paging, searchValue, onlyIntegrated]);

  const columns = React.useMemo<ColumnsType<DataType>>(
    () => [
      {
        key: 'id',
        title: 'ID',
        dataIndex: 'id',
        render: (v) => `${v}`,
        fixed: true,
      },
      {
        key: 'hasIntegrated',
        title: 'Integrated',
        dataIndex: 'hasIntegrated',
        render: (v) => (
          <div
            style={{
              height: '12px',
              width: '12px',
              backgroundColor: v ? `#0b9f6e` : '#ef5353',
              borderRadius: '50%',
            }}
          />
        ),
        fixed: true,
      },
      {
        key: 'logoImage',
        title: 'Logo',
        dataIndex: 'logoImage',
        fixed: true,
        render: (v: ImageEntity) => (v?.id ? <Image id={v?.id} /> : 'No Logo'),
      },
      {
        key: 'email',
        title: 'Email',
        dataIndex: 'email',
        fixed: true,
        render: (v) => `${v}`,
      },
      {
        key: 'createdAt',
        title: 'Created at',
        dataIndex: 'createdAt',
        render: (v) => `${v}`,
      },
      {
        key: 'deletedAt',
        title: 'Deleted at',
        dataIndex: 'deletedAt',
        render: (v) => `${v}`,
      },
      {
        key: 'firstName',
        title: 'First name',
        dataIndex: 'firstName',
        render: (v) => `${v}`,
      },
      {
        key: 'lastName',
        title: 'Last name',
        dataIndex: 'lastName',
        render: (v) => `${v}`,
      },
      {
        key: 'onboardingStep',
        title: 'Onboarding',
        dataIndex: 'onboardingStep',
        render: (v) => `${v === null ? 'Onboarded' : v}`,
      },
      {
        key: 'followUpSent',
        title: '1 Folow Up',
        dataIndex: 'followUpSent',
        render: (v) => `${v ? 'Yes' : 'No'}`,
      },
      {
        key: 'followUpSent',
        title: '2 Folow Up',
        dataIndex: 'secondFollowUpSent',
        render: (v) => `${v ? 'Yes' : 'No'}`,
      },
      {
        key: 'phoneNumber',
        title: 'Phone number',
        dataIndex: 'phoneNumber',
        render: (v) => `${v}`,
      },
      {
        key: 'active',
        title: 'Active',
        dataIndex: 'active',
        render: (v) => `${v}`,
      },
      // {
      //   key: 'activeEmailNotifications',
      //   title: 'Active Email Notifications',
      //   dataIndex: 'activeEmailNotifications',
      //   render: (v) => `${v}`,
      // },
      // {
      //   key: 'activeWeeklyReport',
      //   title: 'Active Weekly Report',
      //   dataIndex: 'activeWeeklyReport',
      //   render: (v) => `${v}`,
      // },
      {
        key: 'notificationEmail',
        title: 'Notification Email',
        dataIndex: 'notificationEmail',
        render: (v) => `${v}`,
      },
      {
        key: 'timezone',
        title: 'Timezone',
        dataIndex: 'timezone',
        render: (v) => `${v}`,
      },
    ],
    [],
  );

  // const onSearchChange = React.useCallback(
  //   (e: React.ChangeEvent<HTMLInputElement>) => {
  //     console.log('MRALE');
  //     debounce(() => {
  //       setSearchValue(e.target.value);
  //     }, 500);
  //   },
  //   [],
  // );

  return (
    <Card bordered={false} title="Users">
      <div
        style={{ marginBottom: 25, display: 'flex', flexDirection: 'column' }}
      >
        <span style={{ marginBottom: 15 }}>
          <strong>Total:</strong>
          {dataSource?.[1] || 'Loading'}
        </span>
        <Input
          placeholder="Search"
          type="text"
          onChange={debounce((e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchValue(e.target.value);
          }, 250)}
          style={{ width: 'fit-content', marginBottom: 10 }}
          disabled={loading}
        />
        <Checkbox
          onChange={(e) => {
            setOnlyIntegrated(e.target.checked);
          }}
          disabled={loading}
        >
          Show only integrated
        </Checkbox>
      </div>
      {dataSource ? (
        <>
          <Table
            loading={loading}
            scroll={{ x: true }}
            dataSource={dataSource[0]}
            columns={columns}
            pagination={{
              total: dataSource[1],
              current: paging.offset / paging.limit + 1,
            }}
            onRow={(record) => ({
              onClick: () => {
                navigate(`${RouterPaths.USERS}/${record.id}`);
              },
            })}
            onChange={(pagination) => {
              if (pagination.current) {
                const size = pagination.pageSize || PER_PAGE_LIMIT;
                const offset = size * (pagination.current - 1);
                setPaging({
                  offset,
                  limit: size,
                });
                setSearchParams(
                  {
                    pageOffset: offset.toString(),
                  },
                  { replace: true },
                );
              }
            }}
          />
        </>
      ) : null}
    </Card>
  );
}

export default UsersPage;
