import { Button, Modal, Select } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';
import { ApiUsersListAllResponse } from '../../services/api/types/users';
import { apiUsersListAll } from '../../services/api/users';

export interface UserSelectorComponentProps {
  onResult?: (userId: number) => void;
  disabled?: boolean;
  defaultSelectedUserId?: number | null;
}

function UserSelectorComponent({
  onResult,
  disabled = false,
  defaultSelectedUserId = null,
}: UserSelectorComponentProps) {
  const { token } = useSelector(selectUserState);

  const [loadedUsers, setLoadedUsers] =
    React.useState<ApiUsersListAllResponse | null>(null);
  const [selectedUser, setSelectedUser] = React.useState<number | null>(
    defaultSelectedUserId,
  );
  const [modalOpened, setModalOpened] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (token) {
      apiUsersListAll(token).then((v) => {
        setLoadedUsers(v);
      });
    }
  }, [modalOpened]);

  async function submitUserSelect() {
    if (onResult && selectedUser) {
      onResult(selectedUser);
    }
    setModalOpened(false);
  }

  const selectedEmail = loadedUsers?.users.find(
    (u) => u.id === selectedUser,
  )?.email;

  return (
    <>
      <Button
        onClick={() => {
          setModalOpened(true);
        }}
        disabled={disabled}
      >
        {selectedEmail || 'Select User'}
      </Button>
      {modalOpened ? (
        <Modal
          title="Users"
          open={modalOpened}
          onCancel={() => {
            setModalOpened(false);
          }}
          onOk={submitUserSelect}
          cancelText={'Abort'}
          okText={'Select'}
        >
          <Select
            showSearch
            style={{ width: '100%', marginBottom: 45 }}
            placeholder="Select existing"
            loading={loadedUsers === null}
            defaultValue={selectedEmail}
            onChange={(userEmail) => {
              const userFoundId = loadedUsers?.users?.find(
                (u) => u.email === userEmail,
              )?.id;
              if (userFoundId) {
                setSelectedUser(userFoundId);
              }
            }}
          >
            {loadedUsers?.users?.map((lf, index) => (
              <Select.Option key={`${lf.id}${index}`} value={lf.email}>
                {lf.email}
              </Select.Option>
            ))}
          </Select>
        </Modal>
      ) : null}
    </>
  );
}

export default UserSelectorComponent;
