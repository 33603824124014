import { API_URL } from './constants';
import {
  MailApiListSendersResponse,
  MailApiSendAnnouncementRequestBody,
  MailApiSendAnnouncementResponse,
} from './types/mail';

export async function apiMailListSenders(
  token: string,
): Promise<MailApiListSendersResponse | null> {
  const url = new URL(`${API_URL}/admin/mail/list-senders`).href;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status === 200) {
    return (await response.json()) as MailApiListSendersResponse;
  }
  return null;
}

export async function apiMailSendAnnouncement(
  body: MailApiSendAnnouncementRequestBody,
  token: string,
): Promise<MailApiSendAnnouncementResponse | null> {
  const url = new URL(`${API_URL}/admin/mail/announcement`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  if (response.status === 201) {
    return (await response.json()) as MailApiSendAnnouncementResponse;
  }
  return null;
}
