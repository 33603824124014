import { Button, Card, Form, Input, Typography, message } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { apiFormsCreatePopup } from '../../services/api/forms';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';
import { ApiFormsCreatePopupRequestBody } from '../../services/api/types/forms';
import { RouterPaths } from '../../router/paths';

interface CreatePopupFormState {
  title: string;
  label: string;
  proceedText: string;
  cancelText: string;
  triggerTime: string;
}

function CreatePopupPage() {
  const { token } = useSelector(selectUserState);
  const { formId } = useParams();
  const navigate = useNavigate();
  const [createPopupForm] = Form.useForm<CreatePopupFormState>();

  const createPopupFormSubmit = React.useCallback(
    async (values: CreatePopupFormState) => {
      if (formId && token && values && Object.keys(values).length) {
        const updateFormRequestBody: ApiFormsCreatePopupRequestBody = {
          formId: parseInt(formId, 10),
          ...values,
          triggerTime: parseInt(values.triggerTime, 10),
        };
        const [response, status] = await apiFormsCreatePopup(
          updateFormRequestBody,
          token,
        );
        if (status === 200 && response.data.success) {
          message.success({ content: 'Form updated successfully!' });
          navigate(`${RouterPaths.FORMS}/${formId}`);
        } else {
          message.error({ content: 'Failed form update!' });
        }
      }
    },
    [token, formId],
  );

  if (!formId) {
    message.error('Missing formId in url!');
    navigate('/');
    return null;
  }

  return (
    <Card bordered={false} title="Single Form Edit">
      <Typography.Title level={2}>Create Popup</Typography.Title>
      <Form form={createPopupForm} onFinish={createPopupFormSubmit}>
        <Form.Item
          label="Title Text"
          name="title"
          required={true}
          initialValue={'Wait, You Still Didn’t Get Your Free Estimate?'}
          rules={[
            {
              max: 512,
              required: true,
              message: 'Title must be 512 chars long!',
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label="Label Text"
          name="label"
          required={true}
          initialValue={'Takes under minute to complete'}
          rules={[
            {
              max: 512,
              required: true,
              message: 'Label must be 512 chars long!',
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label="Button Proceed Text"
          name="proceedText"
          required={true}
          initialValue={'Start Estimation'}
          rules={[
            {
              max: 128,
              required: true,
              message: 'Proceed Text must be 128 chars long!',
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label="Button Cancel Text"
          name="cancelText"
          required={true}
          initialValue={'Not Now'}
          rules={[
            {
              max: 128,
              required: true,
              message: 'Cancel Text must be 128 chars long!',
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label="Trigger time (ms after page load)"
          name="triggerTime"
          required={true}
          initialValue={30000}
          rules={[{ required: true, message: 'Trigger time is required' }]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ marginTop: 55 }}>
            Create and save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default CreatePopupPage;
