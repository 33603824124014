import { createLogger } from 'redux-logger';
import { persistStore } from 'redux-persist';
import rootReducer from './reducers/root.reducer';
import { configureStore } from '@reduxjs/toolkit';

const persistedReducer = rootReducer;

const reduxLogger = createLogger({
  collapsed: true,
  // logger: null,
});

function createStore() {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: [reduxLogger],
  });
  const persistor = persistStore(store);

  return { store, persistor };
}

export default createStore;
