import { Button, Form, FormInstance, Input } from 'antd';
import FileManager from '../FileManager';
import Image from '../Image';
import React from 'react';
import { NamePath } from 'antd/es/form/interface';

export interface ImageInputProps {
  name: NamePath;
  label: string;
  userId?: number;
  form?: FormInstance;
  nameForUpdate?: NamePath;
  required?: boolean;
  defaultImageId?: number;
}

function ImageInput({
  name,
  label,
  userId,
  form,
  nameForUpdate,
  required = true,
  defaultImageId,
}: ImageInputProps) {
  const formI = form || Form.useFormInstance();
  const imageValue = Form.useWatch(nameForUpdate || name, formI);
  const [fileManagerVisible, setFileManagerVisible] = React.useState(false);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '25px',
      }}
    >
      <Form.Item
        label={label}
        name={name}
        rules={[{ required, message: 'Please select image' }]}
        style={{ marginBottom: 0 }}
        initialValue={defaultImageId}
      >
        <Input type="number" style={{ display: 'none' }} />
      </Form.Item>
      {imageValue ? (
        <Image id={imageValue} style={{ height: '32px', margin: '0 20px' }} />
      ) : null}
      <Button
        type="dashed"
        onClick={() => {
          setFileManagerVisible(true);
        }}
      >
        Select logo
      </Button>
      <FileManager
        visible={fileManagerVisible}
        userId={userId}
        onFileSelect={(imageId) => {
          formI.setFieldValue(nameForUpdate || name, imageId);
          setFileManagerVisible(false);
        }}
        onClose={() => {
          setFileManagerVisible(false);
        }}
      />
    </div>
  );
}

export default ImageInput;
