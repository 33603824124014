import { API_URL } from './constants';
import {
  ApiStepsCreateRequestBody,
  ApiStepsCreateResponse,
  ApiStepsDeleteResponse,
  ApiStepsGetResponse,
  ApiStepsUpdateRequestBody,
  ApiStepsUpdateResponse,
} from './types/steps';

export async function apiStepsGet(
  token: string,
  stepId?: number,
): Promise<[ApiStepsGetResponse, number]> {
  const url = new URL(
    `${API_URL}/admin/steps/get${stepId ? `?stepId=${stepId}` : ''}`,
  ).href;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return [(await response.json()) as ApiStepsGetResponse, response.status];
}

export async function apiStepsUpdate(
  token: string,
  body: ApiStepsUpdateRequestBody,
): Promise<[ApiStepsUpdateResponse, number]> {
  const url = new URL(`${API_URL}/admin/steps/update`).href;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [(await response.json()) as ApiStepsUpdateResponse, response.status];
}

export async function apiStepsCreate(
  token: string,
  body: ApiStepsCreateRequestBody,
): Promise<[ApiStepsCreateResponse, number]> {
  const url = new URL(`${API_URL}/admin/steps/create`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [(await response.json()) as ApiStepsCreateResponse, response.status];
}

export async function apiStepsDelete(
  token: string,
  stepId: number,
): Promise<[ApiStepsDeleteResponse, number]> {
  const url = new URL(`${API_URL}/admin/steps/delete?stepId=${stepId}`).href;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return [(await response.json()) as ApiStepsDeleteResponse, response.status];
}
