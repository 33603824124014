import { API_URL } from './constants';
import {
  ApiAgencyUsersCreateRequestBody,
  ApiAgencyUsersCreateResponse,
  ApiAgencyUsersGetResponse,
  ApiAgencyUsersListRequestBody,
  ApiAgencyUsersListResponse,
  ApiAgencyUsersUpdateRequestBody,
  ApiAgencyUsersUpdateResponse,
} from './types/agency-users';

export async function apiAgencyUsersList(
  body: ApiAgencyUsersListRequestBody,
  token: string,
): Promise<ApiAgencyUsersListResponse | null> {
  const url = new URL(`${API_URL}/admin/agency-users/list`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  if (response.status === 201) {
    return (await response.json()) as ApiAgencyUsersListResponse;
  }
  return null;
}

export async function apiAgencyUsersCreate(
  body: ApiAgencyUsersCreateRequestBody,
  token: string,
): Promise<ApiAgencyUsersCreateResponse | null> {
  const url = new URL(`${API_URL}/admin/agency-users/create`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  if (response.status === 201) {
    return (await response.json()) as ApiAgencyUsersCreateResponse;
  }
  return null;
}

export async function apiAgencyUsersGet(
  id: number,
  token: string,
): Promise<[ApiAgencyUsersGetResponse, number]> {
  const url = new URL(`${API_URL}/admin/agency-users/get?id=${id}`).href;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return [
    (await response.json()) as ApiAgencyUsersGetResponse,
    response.status,
  ];
}

export async function apiAgencyUsersUpdate(
  body: ApiAgencyUsersUpdateRequestBody,
  token: string,
): Promise<[ApiAgencyUsersUpdateResponse, number]> {
  const url = new URL(`${API_URL}/admin/agency-users/update`).href;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [
    (await response.json()) as ApiAgencyUsersUpdateResponse,
    response.status,
  ];
}
