import { FormNewFormState, StepsFormState } from '..';
import { CleaningWizardTemplate } from './cleaning';
import { GarageDoorWizardTemplate } from './garage-door';
import { HVACWizardTemplate } from './hvac';
import { LandscapingWizardTemplate } from './landscaping';
import { RoofingWizardTemplate } from './roofing';

export enum WizardFormTemplate {
  ROOFING = 'roofing',
  GARAGE_DOOR = 'garage-door',
  LANDSCAPING = 'landscaping',
  HVAC = 'hvac',
  CLEANING = 'cleaning',
}

export const WizardFormTemplates = [
  WizardFormTemplate.ROOFING,
  WizardFormTemplate.GARAGE_DOOR,
  WizardFormTemplate.LANDSCAPING,
  WizardFormTemplate.HVAC,
  WizardFormTemplate.CLEANING,
];

export const WizardFormEnumToTemplate: Record<
  WizardFormTemplate,
  {
    formF: FormNewFormState;
    stepsF: StepsFormState;
  }
> = {
  [WizardFormTemplate.ROOFING]: RoofingWizardTemplate,
  [WizardFormTemplate.GARAGE_DOOR]: GarageDoorWizardTemplate,
  [WizardFormTemplate.LANDSCAPING]: LandscapingWizardTemplate,
  [WizardFormTemplate.HVAC]: HVACWizardTemplate,
  [WizardFormTemplate.CLEANING]: CleaningWizardTemplate,
};
