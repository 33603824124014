import { Button, Form, Input, Modal, Select, message } from 'antd';
import { Languages, OpenAIModels } from '../../services/api/types';
import {
  AiCreateFormResponse,
  AiListCreatedFormsResponse,
  OpenAIFormRequestLevel,
} from '../../services/api/types/ai';
import React from 'react';
import { apiAiCreateForm, apiAiListCreatedForms } from '../../services/api/ai';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';
import TextArea from 'antd/es/input/TextArea';

export interface AiCreateFormFormValues {
  business: string;
  language: Languages;
  model: OpenAIModels;
  level: OpenAIFormRequestLevel;
  maxNumberOfOptions: number;
  necessaryQuestions?: string;
  services?: string;
}

export interface AiCreateFormComponenProps {
  onResult?: (response: AiCreateFormResponse) => void;
  disabled?: boolean;
}

function AiCreateFormComponent({
  onResult,
  disabled = false,
}: AiCreateFormComponenProps) {
  const { token } = useSelector(selectUserState);

  const [form] = Form.useForm<AiCreateFormFormValues>();
  const [loadedForms, setLoadedForms] =
    React.useState<AiListCreatedFormsResponse | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [aiModalOpened, setAiModalOpened] = React.useState<boolean>(false);

  async function submitCreateForm() {
    const values = form.getFieldsValue();
    if (!values.business?.length || !values.maxNumberOfOptions) {
      message.error('Please fill form correctly!');
    } else {
      form.submit();
    }
  }

  React.useEffect(() => {
    if (!aiModalOpened) {
      form.resetFields();
    }
    if (token) {
      apiAiListCreatedForms(token).then((v) => {
        setLoadedForms(v);
      });
    }
  }, [aiModalOpened]);

  return (
    <>
      <Button
        onClick={() => {
          setAiModalOpened(true);
        }}
        disabled={disabled || isLoading}
      >
        Create with AI
      </Button>
      {aiModalOpened ? (
        <Modal
          title="TapformAI"
          open={aiModalOpened}
          onCancel={() => {
            if (!isLoading) {
              setAiModalOpened(false);
            }
          }}
          onOk={submitCreateForm}
          cancelText={'Abort'}
          okText={'Create'}
          confirmLoading={isLoading}
          className="ai-create-modal"
        >
          <Select
            style={{ width: '100%', marginBottom: 45 }}
            placeholder="Select existing"
            loading={loadedForms === null}
            placement="bottomLeft"
            onChange={(lfn) => {
              const foundForm = loadedForms?.forms.find(
                (lff) => lff.name === lfn,
              );
              if (foundForm) {
                message.success('Successfully selected form!');
                if (onResult) {
                  onResult(foundForm.data);
                }
                setAiModalOpened(false);
              }
            }}
          >
            {loadedForms?.forms?.map((lf, index) => (
              <Select.Option key={`${lf.name}${index}`} value={lf.name}>
                {lf.name}
              </Select.Option>
            ))}
          </Select>
          <Form
            form={form}
            onFinish={async (vals) => {
              if (token) {
                try {
                  setIsLoading(true);
                  const res = await apiAiCreateForm(
                    {
                      business: vals.business,
                      language: vals.language,
                      maxNumberOfOptions: parseInt(
                        vals.maxNumberOfOptions as any,
                        10,
                      ),
                      level: vals.level,
                      model: vals.model,
                      services: vals.services?.length
                        ? vals.services.split(',').map((ser) => ser.trim())
                        : undefined,
                      necessaryQuestions: vals.necessaryQuestions?.length
                        ? vals.necessaryQuestions
                            .split('\n')
                            .map((ser) => ser.trim())
                        : undefined,
                    },
                    token,
                  );
                  if (!res) {
                    message.error('Something went wrong!');
                  }

                  if (onResult && res) {
                    setIsLoading(false);
                    onResult(res);
                  }

                  setAiModalOpened(false);
                  message.success('Form Created!');
                } catch (e) {
                  console.log(e);
                  message.error('Something went wrong!');
                }
              }
            }}
          >
            <Form.Item
              name="business"
              label="Business type"
              rules={[
                {
                  required: true,
                  message: 'Please input business name!',
                },
              ]}
            >
              <Input placeholder="Example: HVAC Business" />
            </Form.Item>
            <Form.Item
              label="Services that business offers"
              name="services"
              labelCol={{ span: 24 }}
              tooltip="Not required, deliminate with (,). Write services that business offers."
            >
              <TextArea
                autoSize={{ minRows: 2 }}
                placeholder={`roof install, roof repair, roof inspection, tile reset`}
              />
            </Form.Item>
            <Form.Item
              label="Necessary questions"
              name="necessaryQuestions"
              tooltip="Not required, deliminate with new text line (enter). Write questions that form needs to have."
              labelCol={{ span: 24 }}
            >
              <TextArea
                autoSize={{ minRows: 2 }}
                placeholder={`When do you need the service?\nHow old are you?`}
              />
            </Form.Item>
            <Form.Item
              label="Max options per question"
              name="maxNumberOfOptions"
              initialValue={6}
              required
              rules={[
                {
                  required: true,
                  message: 'Please input max number of options!',
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              label="AI Engine"
              name="model"
              initialValue={OpenAIModels.GPT_3_5_TURBO}
            >
              <Select>
                {Object.values(OpenAIModels).map((tz) => (
                  <Select.Option key={tz} value={tz}>
                    {tz}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Form Language"
              name="language"
              initialValue={Languages.ENGLISH}
            >
              <Select>
                {Object.values(Languages).map((tz) => (
                  <Select.Option key={tz} value={tz}>
                    {tz}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Form Skill"
              name="level"
              initialValue={OpenAIFormRequestLevel.EASY}
            >
              <Select>
                {Object.values(OpenAIFormRequestLevel).map((tz) => (
                  <Select.Option key={tz} value={tz}>
                    {tz}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      ) : null}
    </>
  );
}

export default AiCreateFormComponent;
