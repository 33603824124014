/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/return-await */
import {
  Button,
  Card,
  Form,
  Input,
  message,
  Select,
  Switch,
  Typography,
} from 'antd';
import React from 'react';
import generator from 'generate-password-browser';
import Timezone from 'timezone-enum';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { apiUsersCreate } from '../../services/api/users';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';
import {
  ApiUsersCreateRequestBody,
  ApiUsersCreateResponse,
} from '../../services/api/types/users';
import ImageInput from '../../components/ImageInput/imageInput';
import { ApiFormsCreateWizardRequestBody } from '../../services/api/types/forms';
import { apiFormsCreateWizard } from '../../services/api/forms';
import {
  WizardFormEnumToTemplate,
  WizardFormTemplate,
  WizardFormTemplates,
} from './templates';
import AiCreateFormComponent from '../../components/AiCreateForm';
import { ALLOWED_INPUT_TYPES, InputType } from '../../services/api/types/steps';
import UserSelectorComponent from '../../components/UserSelector';
import { AIFormVersion } from '../../services/api/types/ai';
import { useNavigate } from 'react-router';
import { RouterPaths } from '../../router/paths';

export interface UserFormState {
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  phoneNumber?: string;
  activeEmailNotifications: boolean;
  activeWeeklyReport: boolean;
  timezone?: Timezone;
}

export interface FormNewFormState {
  name?: string;
  slug?: string;
  brandColor?: string;
  origin?: string;
  widgetTitle: string;
  widgetButtonText: string;
  logoImageId?: number;
  developmentMode: boolean;
  requirePhone: boolean;
  requireAddress: boolean;
  requireAdditionalNotes: boolean;
  linkable: boolean;
  createDefaultPopup: boolean;
  resultScreenButtonRedirect: string | null;
  resultScreenRedirect: string | null;
}

export interface OneOptionData {
  value: string;
  order: number;
  resultTitle?: string;
  resultDescription?: string;
  resultMinPrice?: number;
  imageId?: number | null;
  resultImageId?: number;
  nextQuestionIndex: number | null;
}

export interface OneStepData {
  title?: string;
  isFinalStep: boolean;
  order: number;
  submitButtonText: string;
  type: InputType | null;
  inputLabel?: string | null;
  options: OneOptionData[];
  nextQuestionIndex: number | null;
  dataAnnotation: string | null;
}

export interface StepsFormState {
  steps: OneStepData[];
}

export function CreateWizardPage() {
  const { token } = useSelector(selectUserState);
  const navigate = useNavigate();

  const [userForm] = Form.useForm<UserFormState>();
  const [newFormForm] = Form.useForm<FormNewFormState>();
  const [stepsForm] = Form.useForm<StepsFormState>();

  const [userSaved, setUserSaved] = React.useState<
    ApiUsersCreateResponse | number | null
  >(null);

  const stepsValues = Form.useWatch('steps', stepsForm);

  const [createLoading, setCreateLoading] = React.useState<boolean>(false);

  const checkIfDisabled = React.useCallback(() => {
    const newFormValues = newFormForm.getFieldsValue();
    const stepsValues = stepsForm.getFieldsValue()?.steps;
    if (!userSaved) {
      return true;
    } else if (
      !newFormValues?.brandColor ||
      !newFormValues?.logoImageId ||
      !newFormValues?.name ||
      !newFormValues?.origin ||
      !newFormValues?.slug ||
      !newFormValues?.widgetButtonText ||
      !newFormValues?.widgetTitle
    ) {
      return true;
    } else if (
      !stepsValues?.length ||
      stepsValues?.filter((step) => step?.isFinalStep).length !== 1 ||
      stepsValues?.filter((step) => {
        if (
          ((!step?.options || !step?.options.length) &&
            !step?.isFinalStep &&
            step.type !== InputType.TEXT_INPUT) ||
          step?.order === undefined ||
          !step?.submitButtonText ||
          !step?.title ||
          (!step.isFinalStep && typeof step.nextQuestionIndex !== 'number')
        ) {
          return true;
        }
        return false;
      }).length
    ) {
      return true;
    } else if (
      stepsValues?.filter((step) => {
        if (step.type === InputType.TEXT_INPUT && !step.inputLabel?.length) {
          return true;
        }
        if (
          (!step.isFinalStep &&
            step?.options?.filter((option) => {
              if (!step.isFinalStep && step.type === null) {
                return true;
              }
              if (
                (step.type === InputType.SINGLE_SELECT ||
                  step.type === InputType.MULTIPLE_SELECT) &&
                !option.imageId
              ) {
                return true;
              }
              if (option?.order === undefined || option?.value === undefined) {
                return true;
              }
              return false;
            }).length) ||
          step?.options?.length < 2
        ) {
          return true;
        }
        return false;
      }).length
    ) {
      return true;
    }
    return false;
  }, [userSaved, newFormForm, stepsForm]);

  const submitFormWizard = React.useCallback(async () => {
    const newFormValues = newFormForm.getFieldsValue();
    const stepsValues = stepsForm.getFieldsValue()?.steps;
    if (checkIfDisabled()) {
      message.error('Please fill correctly.');
      return;
    }
    if (userSaved && token) {
      const requestBody: ApiFormsCreateWizardRequestBody = {
        userId: typeof userSaved === 'number' ? userSaved : userSaved.id,
        form: newFormValues,
        steps: stepsValues.map((step) => ({
          ...step,
          order: parseInt(step.order as any, 10),
          type: step.isFinalStep ? null : step.type,
          nextQuestionIndex: !Number.isNaN(
            parseInt(step.nextQuestionIndex as any),
          )
            ? parseInt(step.nextQuestionIndex as any)
            : null,
          inputLabel:
            !step.isFinalStep && step.type === InputType.TEXT_INPUT
              ? step.inputLabel
              : null,
          options:
            step.options &&
            !step.isFinalStep &&
            step.type !== InputType.TEXT_INPUT
              ? step.options.map((option) => ({
                  ...option,
                  nextQuestionIndex: !Number.isNaN(
                    parseInt(option.nextQuestionIndex as any),
                  )
                    ? parseInt(option.nextQuestionIndex as any)
                    : null,
                  order: parseInt(option.order as any, 10),
                  imageId:
                    option.imageId &&
                    (step.type === InputType.SINGLE_SELECT ||
                      step.type === InputType.MULTIPLE_SELECT)
                      ? option.imageId
                      : null,
                }))
              : [],
        })),
      } as ApiFormsCreateWizardRequestBody;

      setCreateLoading(true);
      const [response, status] = await apiFormsCreateWizard(requestBody, token);
      setCreateLoading(false);
      if (status === 201) {
        message.open({
          type: 'success',
          content: 'Form created successfuly!',
        });
        setUserSaved(null);
        userForm.resetFields();
        newFormForm.resetFields();
        stepsForm.resetFields();
        setTimeout(() => {
          navigate(RouterPaths.USERS);
        }, 0);
      } else {
        message.open({
          type: 'error',
          content: response.message,
        });
      }
    }
  }, [checkIfDisabled, userSaved, newFormForm, stepsForm, token]);

  const createUserCallback = React.useCallback(
    async (values: UserFormState) => {
      if (token) {
        if (
          values.firstName &&
          values.lastName &&
          values.email &&
          values.password &&
          values.timezone
        ) {
          const createdUserResponse = await apiUsersCreate(
            values as ApiUsersCreateRequestBody,
            token,
          );
          if (!createdUserResponse) {
            message.open({
              type: 'error',
              content: 'Something went wrong!',
            });
          } else {
            setUserSaved(createdUserResponse);
            message.open({
              type: 'success',
              content: 'User created',
            });
          }
        } else {
          message.open({
            type: 'error',
            content: 'Missing required fields!',
          });
        }
      } else {
        message.open({
          type: 'error',
          content: 'Authorization error',
        });
      }
    },
    [token],
  );

  return (
    <Card bordered={false} title="Create wizard">
      <Typography.Title level={2}>User</Typography.Title>
      <div style={{ width: '100%', display: 'flex', marginBottom: '25px' }}>
        <UserSelectorComponent
          onResult={(userIdSelected) => {
            setUserSaved(userIdSelected);
          }}
        />
      </div>
      <Form
        form={userForm}
        onFinish={createUserCallback}
        disabled={!!userSaved}
      >
        <Form.Item
          label="First Name"
          name="firstName"
          rules={[
            { required: true, message: 'Please input first name!', max: 100 },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[
            { required: true, message: 'Please input last name!', max: 100 },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            { required: true, message: 'Please input email!', max: 255 },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Phone number"
          name="phoneNumber"
          rules={[
            {
              required: false,
              message: 'Please input phone number!',
              max: 255,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            { required: true, message: 'Please input password!', max: 255 },
          ]}
          initialValue={generator.generate({ length: 8, numbers: true })}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Active Email Notifications"
          name="activeEmailNotifications"
          valuePropName="checked"
          initialValue={true}
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label="Active Weekly Reports"
          name="activeWeeklyReport"
          valuePropName="checked"
          initialValue={true}
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label="Timezone"
          name="timezone"
          initialValue={Timezone['US/Central']}
        >
          <Select>
            {Object.values(Timezone).map((tz) => (
              <Select.Option key={tz} value={tz}>
                {tz}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create New User
          </Button>
        </Form.Item>
      </Form>

      <Typography.Title level={2}>Form</Typography.Title>
      <div style={{ display: 'flex', width: '100%' }}>
        <Select
          placeholder={'Select template'}
          disabled={!userSaved}
          defaultActiveFirstOption={false}
          style={{
            marginBottom: 45,
            width: 250,
            textTransform: 'capitalize',
            marginRight: 20,
          }}
          onChange={(v: WizardFormTemplate) => {
            const template = WizardFormEnumToTemplate[v];
            newFormForm.setFieldValue('name', template.formF.name);
            newFormForm.setFieldValue('slug', template.formF.slug);
            newFormForm.setFieldValue('brandColor', template.formF.brandColor);
            newFormForm.setFieldValue('origin', template.formF.origin);
            newFormForm.setFieldValue(
              'widgetTitle',
              template.formF.widgetTitle,
            );
            newFormForm.setFieldValue(
              'widgetButtonText',
              template.formF.widgetButtonText,
            );
            newFormForm.setFieldValue(
              'developmentMode',
              template.formF.developmentMode,
            );
            newFormForm.setFieldValue(
              'requirePhone',
              template.formF.requirePhone,
            );
            newFormForm.setFieldValue(
              'requireAddress',
              template.formF.requireAddress,
            );
            newFormForm.setFieldValue(
              'requireAdditionalNotes',
              template.formF.requireAdditionalNotes,
            );
            newFormForm.setFieldValue('linkable', template.formF.linkable);

            stepsForm.setFieldsValue({
              steps: template.stepsF.steps,
            });
          }}
        >
          {WizardFormTemplates.map((wft, ind) => (
            <Select.Option
              key={`wtf_${ind}_${wft}`}
              value={wft}
              style={{ textTransform: 'capitalize' }}
            >
              {wft}
            </Select.Option>
          ))}
        </Select>
        <AiCreateFormComponent
          // disabled={!userSaved}
          onResult={(res) => {
            const stepsToCreate: OneStepData[] = [];
            res.questions.forEach((question, index) => {
              if (
                (question.type === InputType.SINGLE_SELECT_NO_ICON ||
                  question.type === InputType.SINGLE_SELECT ||
                  question.type === InputType.MULTIPLE_SELECT ||
                  question.type === InputType.MULTIPLE_SELECT_NO_ICON) &&
                question.options
              ) {
                stepsToCreate.push({
                  title: question.question,
                  isFinalStep: false,
                  order: index,
                  submitButtonText: res.submitButtonText,
                  dataAnnotation: null,
                  type:
                    question.type === InputType.MULTIPLE_SELECT ||
                    question.type === InputType.MULTIPLE_SELECT_NO_ICON
                      ? InputType.MULTIPLE_SELECT_NO_ICON
                      : InputType.SINGLE_SELECT_NO_ICON,
                  nextQuestionIndex:
                    res.version === AIFormVersion.V3 &&
                    typeof question.nextQuestionId === 'number' &&
                    res.questions.findIndex(
                      (rq) => rq.questionId === question.nextQuestionId,
                    ) !== -1
                      ? res.questions.findIndex(
                          (rq) => rq.questionId === question.nextQuestionId,
                        )
                      : index + 1,
                  options: question.options.map((opt, optIndex) => {
                    return {
                      value: opt.value,
                      order: optIndex,
                      resultTitle:
                        index === 0 ? res.resultTitle : (undefined as any),
                      resultDescription:
                        index === 0
                          ? res.resultDescription
                          : (undefined as any),
                      imageId: null,
                      resultImageId: null as any,
                      nextQuestionIndex:
                        res.version === AIFormVersion.V3 &&
                        typeof opt.nextQuestionId === 'number' &&
                        res.questions.findIndex(
                          (rq) => rq.questionId === opt.nextQuestionId,
                        ) !== -1
                          ? res.questions.findIndex(
                              (rq) => rq.questionId === opt.nextQuestionId,
                            )
                          : null,
                    };
                  }),
                });
              } else {
                stepsToCreate.push({
                  title: question.question,
                  isFinalStep: false,
                  order: index,
                  submitButtonText: res.submitButtonText,
                  inputLabel: question.placeholder,
                  type: InputType.TEXT_INPUT,
                  dataAnnotation: null,
                  nextQuestionIndex:
                    res.version === AIFormVersion.V3 &&
                    typeof question.nextQuestionId === 'number' &&
                    res.questions.findIndex(
                      (rq) => rq.questionId === question.nextQuestionId,
                    ) !== -1
                      ? res.questions.findIndex(
                          (rq) => rq.questionId === question.nextQuestionId,
                        )
                      : res.questions.length,
                  options: undefined as any,
                });
              }
            });
            stepsToCreate.push({
              isFinalStep: true,
              title: res.lastStepTitle,
              order: res.questions.length,
              submitButtonText: res.lastStepButtonText,
              options: undefined as any,
              nextQuestionIndex: null,
              type: null,
              dataAnnotation: null,
            });
            stepsForm.setFieldsValue({
              steps: stepsToCreate,
            });
          }}
        />
      </div>
      <Form form={newFormForm} disabled={!userSaved}>
        <ImageInput
          name="logoImageId"
          label="Form Logo"
          userId={typeof userSaved === 'number' ? userSaved : userSaved?.id}
        />
        <Form.Item
          label="Form name"
          name="name"
          rules={[
            { required: true, message: 'Please input form name!', max: 50 },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Slug"
          name="slug"
          rules={[{ required: true, message: 'Please input slug!', max: 50 }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Brand Color"
          name="brandColor"
          rules={[
            { required: true, message: 'Please input brand color!', max: 24 },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Origin"
          name="origin"
          rules={[
            { required: true, message: 'Please input origin!', max: 512 },
          ]}
        >
          <Input placeholder="https://example.com/" />
        </Form.Item>
        <Form.Item
          label="Widget Title"
          name="widgetTitle"
          rules={[{ required: true, message: 'Please widget title!', max: 32 }]}
          initialValue={'Get estimate for free'}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Widget Button Text"
          name="widgetButtonText"
          rules={[{ required: true, message: 'Please widget title!', max: 20 }]}
          initialValue={'Start estimation'}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Result Screen Button Redirect"
          name="resultScreenButtonRedirect"
          rules={[
            {
              required: false,
              max: 2048,
              message: 'Result Screen Button Redirect: too long',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Result Screen Redirect"
          name="resultScreenRedirect"
          rules={[
            {
              required: false,
              max: 2048,
              message: 'Result Screen Redirect: too long',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Development Mode"
          name="developmentMode"
          valuePropName="checked"
          initialValue={false}
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label="Require Phone"
          name="requirePhone"
          valuePropName="checked"
          initialValue={true}
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label="Require Address"
          name="requireAddress"
          valuePropName="checked"
          initialValue={true}
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label="Require Additional Notes"
          name="requireAdditionalNotes"
          valuePropName="checked"
          initialValue={true}
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label="Create default popup"
          name="createDefaultPopup"
          valuePropName="checked"
          initialValue={true}
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label="Form Linkable"
          name="linkable"
          valuePropName="checked"
          initialValue={true}
        >
          <Switch />
        </Form.Item>
      </Form>

      <Typography.Title level={2}>Steps</Typography.Title>
      <Form form={stepsForm} disabled={!userSaved}>
        <Form.List
          name="steps"
          rules={[
            {
              validator: async (_, names) => {
                if (!names || names.length < 1) {
                  return Promise.reject(new Error('At least 1 step!'));
                }
              },
            },
            {
              validator: async (_, names) => {
                if (names && !names.find((name: any) => name.isLastStep)) {
                  return Promise.reject(new Error('At least one final step!'));
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, fieldIndex) => (
                <Card
                  style={{ margin: '20px', background: 'aliceblue' }}
                  key={field.key}
                >
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.area !== curValues.area ||
                      prevValues.sights !== curValues.sights
                    }
                  >
                    <Form.Item
                      label="Title"
                      name={[field.name, 'title']}
                      rules={[
                        {
                          required: true,
                          message: 'Please input step title!',
                          max: 512,
                        },
                      ]}
                    >
                      <Input placeholder="Do you need service?" />
                    </Form.Item>
                    <Form.Item
                      label="Order"
                      name={[field.name, 'order']}
                      rules={[
                        {
                          required: true,
                          message: 'Please input step order!',
                          max: 512,
                        },
                      ]}
                      initialValue={0}
                    >
                      <Input type="number" />
                    </Form.Item>
                    <Form.Item
                      label="Submit Button Text"
                      name={[field.name, 'submitButtonText']}
                      rules={[
                        {
                          required: true,
                          message: 'Please input step submit button text!',
                          max: 128,
                        },
                      ]}
                      initialValue={'Continue'}
                    >
                      <Input />
                    </Form.Item>
                    {!stepsValues?.[fieldIndex]?.isFinalStep ? (
                      <Form.Item
                        label="Next question"
                        name={[field.name, 'nextQuestionIndex']}
                      >
                        <Select placeholder="Select next question">
                          {stepsValues?.map((sv, ind) => (
                            <Select.Option
                              key={`${sv?.title || ''}${ind}`}
                              value={ind}
                            >
                              {sv?.title}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    ) : null}
                    {!stepsValues?.[fieldIndex]?.isFinalStep ? (
                      <Form.Item
                        label="Input Type"
                        name={[field.name, 'type']}
                        rules={[
                          {
                            required: true,
                            message: 'Please select input type',
                            max: 128,
                          },
                        ]}
                        initialValue={InputType.SINGLE_SELECT}
                      >
                        <Select>
                          {ALLOWED_INPUT_TYPES.map((inputType) => (
                            <Select.Option key={inputType} value={inputType}>
                              {inputType}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    ) : null}
                    {stepsValues?.[fieldIndex]?.type ===
                    InputType.TEXT_INPUT ? (
                      <Form.Item
                        label="Input Label"
                        name={[field.name, 'inputLabel']}
                        rules={[
                          {
                            required: true,
                            message: 'Please input label button text!',
                            max: 128,
                          },
                        ]}
                        initialValue={'Email'}
                      >
                        <Input />
                      </Form.Item>
                    ) : null}
                    {!stepsValues?.[fieldIndex]?.isFinalStep ? (
                      <Form.Item
                        label="Data Annotation"
                        name={[field.name, 'dataAnnotation']}
                        rules={[
                          {
                            required: false,
                            message: 'Max data annotation is 124 chars',
                            max: 124,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    ) : null}
                    <Form.Item
                      label="Is final step"
                      name={[field.name, 'isFinalStep']}
                      valuePropName="checked"
                      initialValue={false}
                    >
                      <Switch />
                    </Form.Item>
                    {!stepsValues?.[fieldIndex]?.isFinalStep &&
                    stepsValues?.[fieldIndex]?.type !== InputType.TEXT_INPUT ? (
                      <Form.List
                        name={[field.name, 'options']}
                        rules={[
                          {
                            validator: async (_, names) => {
                              if (!names || names.length < 2) {
                                return Promise.reject(
                                  new Error('At least 2 options!'),
                                );
                              }
                            },
                          },
                        ]}
                      >
                        {(
                          optionFields,
                          { add: optionAdd, remove: optionRemove },
                        ) => (
                          <>
                            {optionFields.map((optionField) => (
                              <Card
                                style={{ margin: '20px' }}
                                key={optionField.key}
                              >
                                <Form.Item noStyle preserve={false}>
                                  <Form.Item
                                    label="Value"
                                    name={[optionField.name, 'value']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input option value!',
                                        max: 512,
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Yes" />
                                  </Form.Item>
                                  <Form.Item
                                    label="Order"
                                    name={[optionField.name, 'order']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input option order!',
                                        max: 512,
                                      },
                                    ]}
                                    initialValue={0}
                                  >
                                    <Input type="number" />
                                  </Form.Item>
                                  {stepsValues?.[fieldIndex]?.type ===
                                    InputType.SINGLE_SELECT ||
                                  stepsValues?.[fieldIndex]?.type ===
                                    InputType.MULTIPLE_SELECT ? (
                                    <ImageInput
                                      name={[optionField.name, 'imageId']}
                                      label="Option Image"
                                      userId={
                                        typeof userSaved === 'number'
                                          ? userSaved
                                          : userSaved?.id
                                      }
                                      nameForUpdate={[
                                        'steps',
                                        field.name,
                                        'options',
                                        optionField.name,
                                        'imageId',
                                      ]}
                                    />
                                  ) : null}
                                  {stepsValues?.[fieldIndex]?.type !==
                                  InputType.TEXT_INPUT ? (
                                    <Form.Item
                                      label="Next question"
                                      name={[
                                        optionField.name,
                                        'nextQuestionIndex',
                                      ]}
                                    >
                                      <Select placeholder="Select next question">
                                        {stepsValues?.map((sv, ind) => (
                                          <Select.Option
                                            key={`${sv?.title || ''}${ind}`}
                                            value={ind}
                                          >
                                            {sv?.title}
                                          </Select.Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                  ) : null}
                                  <Form.Item
                                    label="Result Title"
                                    name={[optionField.name, 'resultTitle']}
                                    rules={[
                                      {
                                        required: false,
                                        message: 'Max length 512!',
                                        max: 512,
                                      },
                                    ]}
                                  >
                                    <Input />
                                  </Form.Item>
                                  <Form.Item
                                    label="Result Description"
                                    name={[
                                      optionField.name,
                                      'resultDescription',
                                    ]}
                                    rules={[
                                      {
                                        required: false,
                                        message: 'Max length 512!',
                                        max: 512,
                                      },
                                    ]}
                                  >
                                    <Input />
                                  </Form.Item>
                                  <Form.Item
                                    label="Result Min Price in cents"
                                    name={[optionField.name, 'resultMinPrice']}
                                    rules={[
                                      {
                                        required: false,
                                      },
                                    ]}
                                    normalize={(v) => parseInt(v, 10)}
                                  >
                                    <Input type="number" />
                                  </Form.Item>
                                  <ImageInput
                                    name={[optionField.name, 'resultImageId']}
                                    label="Result Image"
                                    userId={
                                      typeof userSaved === 'number'
                                        ? userSaved
                                        : userSaved?.id
                                    }
                                    nameForUpdate={[
                                      'steps',
                                      field.name,
                                      'options',
                                      optionField.name,
                                      'resultImageId',
                                    ]}
                                    required={false}
                                  />
                                  <Button
                                    type="dashed"
                                    danger
                                    onClick={() => {
                                      optionRemove(optionField.name);
                                    }}
                                    block
                                    icon={<CloseOutlined />}
                                  >
                                    Remove option
                                  </Button>
                                </Form.Item>
                              </Card>
                            ))}
                            <Form.Item>
                              <Button
                                type="dashed"
                                onClick={() => {
                                  optionAdd();
                                }}
                                block
                                icon={<PlusOutlined />}
                              >
                                Add option
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    ) : null}
                    <Button
                      type="dashed"
                      onClick={() => {
                        remove(field.name);
                      }}
                      block
                      danger
                      icon={<CloseOutlined />}
                    >
                      Remove step
                    </Button>
                  </Form.Item>
                </Card>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                  block
                  icon={<PlusOutlined />}
                >
                  Add step
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
      <Button
        style={{ marginTop: '40px', width: '100%', height: '50px' }}
        type="primary"
        onClick={submitFormWizard}
        loading={createLoading}
        disabled={createLoading}
      >
        Save user form
      </Button>
    </Card>
  );
}

export default CreateWizardPage;
