import { API_URL } from './constants';
import {
  AiCreateFormRequestBody,
  AiCreateFormResponse,
  AiListCreatedFormsResponse,
} from './types/ai';

export async function apiAiCreateForm(
  body: AiCreateFormRequestBody,
  token: string,
): Promise<AiCreateFormResponse | null> {
  const url = new URL(`${API_URL}/admin/ai/create-form`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return (await response.json()) as AiCreateFormResponse;
}

export async function apiAiListCreatedForms(
  token: string,
): Promise<AiListCreatedFormsResponse | null> {
  const url = new URL(`${API_URL}/admin/ai/list-created-forms`).href;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return (await response.json()) as AiListCreatedFormsResponse;
}
