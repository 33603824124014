export interface SuccessResponseData {
  success: boolean;
}

export enum Languages {
  ENGLISH = 'english',
  CROATIAN = 'croatian',
  GERMAN = 'german',
  FRENCH = 'french',
  SPANISH = 'spanish',
  RUSSIAN = 'russian',
  TURKISH = 'turkish',
  JAPANESE = 'japanese',
  PORTUGUESE = 'portuguese',
  ARABIC = 'arabic',
  ITALIAN = 'italian',
  SERBIAN = 'serbian',
  KOREAN = 'korean',
}

export enum OpenAIModels {
  GPT_4 = 'gpt-4',
  GPT_3_5_TURBO = 'gpt-3.5-turbo',
}
