import { API_URL } from './constants';
import {
  ApiUsersCreateRequestBody,
  ApiUsersCreateResponse,
  ApiUsersDeleteRequestBody,
  ApiUsersDeleteResponse,
  ApiUsersGetResponse,
  ApiUsersListAllResponse,
  ApiUsersListRequestBody,
  ApiUsersListResponse,
  ApiUsersUpdateRequestBody,
  ApiUsersUpdateResponse,
} from './types/users';

export async function apiUsersList(
  body: ApiUsersListRequestBody,
  token: string,
): Promise<ApiUsersListResponse | null> {
  const url = new URL(`${API_URL}/admin/users/list`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  if (response.status === 201) {
    return (await response.json()) as ApiUsersListResponse;
  }
  return null;
}

export async function apiUsersListAll(
  token: string,
): Promise<ApiUsersListAllResponse | null> {
  const url = new URL(`${API_URL}/admin/users/list-all`).href;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status === 200) {
    return (await response.json()) as ApiUsersListAllResponse;
  }
  return null;
}

export async function apiUsersCreate(
  body: ApiUsersCreateRequestBody,
  token: string,
): Promise<ApiUsersCreateResponse | null> {
  const url = new URL(`${API_URL}/admin/users/create`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  if (response.status === 201) {
    return (await response.json()) as ApiUsersCreateResponse;
  }
  return null;
}

export async function apiUsersGet(
  userId: number,
  token: string,
): Promise<[ApiUsersGetResponse, number]> {
  const url = new URL(`${API_URL}/admin/users/get?userId=${userId}`).href;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return [(await response.json()) as ApiUsersGetResponse, response.status];
}

export async function apiUsersUpdate(
  body: ApiUsersUpdateRequestBody,
  token: string,
): Promise<[ApiUsersUpdateResponse, number]> {
  const url = new URL(`${API_URL}/admin/users/update`).href;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [(await response.json()) as ApiUsersUpdateResponse, response.status];
}

export async function apiUsersDelete(
  body: ApiUsersDeleteRequestBody,
  token: string,
): Promise<[ApiUsersDeleteResponse, number]> {
  const url = new URL(`${API_URL}/admin/users/delete`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [(await response.json()) as ApiUsersDeleteResponse, response.status];
}
