/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react';
import { Card, Checkbox, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';
import { RegistrationEntity } from '../../services/api/types/registrations';
import { apiRegistrationsList } from '../../services/api/registrations';

interface DataType extends RegistrationEntity {
  key: string;
}

const PER_PAGE_LIMIT = 10;

function RegistrationsPage() {
  const [offset, setOffset] = React.useState(0);
  const [onlyUnverified, setOnlyUnverified] = React.useState(true);
  const [dataSource, setDataSource] = React.useState<
    [DataType[], number] | null
  >(null);
  const { token } = useSelector(selectUserState);
  // const navigate = useNavigate();

  React.useEffect(() => {
    if (token) {
      (async () => {
        const response = await apiRegistrationsList(
          { offset, limit: PER_PAGE_LIMIT, onlyUnverified },
          token,
        );
        if (response) {
          if (dataSource) {
            setDataSource([
              [
                ...dataSource[0],
                ...response.registrations.map((reg) => ({
                  ...reg,
                  key: reg.id.toString(),
                })),
              ],
              response.total,
            ]);
          } else {
            setDataSource([
              response.registrations.map((reg) => ({
                ...reg,
                key: reg.id.toString(),
              })),
              response.total,
            ]);
          }
          setDataSource([
            response.registrations.map((reg) => ({
              ...reg,
              key: reg.id.toString(),
            })),
            response.total,
          ]);
        } else {
          setDataSource([[], 0]);
        }
      })();
    }
  }, [offset, onlyUnverified]);

  const columns = React.useMemo<ColumnsType<DataType>>(
    () => [
      {
        key: 'id',
        title: 'ID',
        dataIndex: 'id',
        render: (v) => `${v}`,
        fixed: true,
      },
      {
        key: 'email',
        title: 'Email',
        dataIndex: 'email',
        fixed: true,
        render: (v) => `${v}`,
      },
      {
        key: 'createdAt',
        title: 'Created At',
        dataIndex: 'createdAt',
        fixed: true,
        render: (v) => `${v}`,
      },
      {
        key: 'deletedAt',
        title: 'Verified At',
        dataIndex: 'deletedAt',
        render: (v) => (v ? `${v}` : 'Not verified'),
      },
      {
        key: 'resendToken',
        title: 'Resend Token',
        dataIndex: 'resendToken',
        render: (v) => `${v}`,
      },
      {
        key: 'registrationToken',
        title: 'Registration Token',
        dataIndex: 'registrationToken',
        render: (v) => `${v}`,
      },
    ],
    [],
  );

  return (
    <Card bordered={false} title="Users">
      {dataSource ? (
        <>
          <div
            style={{
              display: 'flex',
              width: '100%',
            }}
          >
            <Checkbox
              checked={onlyUnverified}
              onChange={() => {
                setOffset(0);
                setOnlyUnverified(!onlyUnverified);
                console.log(!onlyUnverified);
              }}
            >
              Include only unverified
            </Checkbox>
          </div>
          <Table
            scroll={{ x: true }}
            dataSource={dataSource[0]}
            columns={columns}
            pagination={{
              total: dataSource[1],
              current: offset / 10 + 1,
            }}
            // TODO: email -> user id click if verified
            // onRow={(record) => ({
            //   onClick: () => {
            //     navigate(`${RouterPaths.REGISTRATIONS}/${record.id}`);
            //   },
            // })}
            onChange={(pagination) => {
              if (pagination.current) {
                setOffset(10 * (pagination.current - 1));
              }
            }}
          />
        </>
      ) : null}
    </Card>
  );
}

export default RegistrationsPage;
