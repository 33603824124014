import {
  ApiRegistrationsListRequest,
  ApiRegistrationsListResponse,
} from './types/registrations';
import { API_URL } from './constants';

export async function apiRegistrationsList(
  body: ApiRegistrationsListRequest,
  token: string,
): Promise<ApiRegistrationsListResponse | null> {
  const url = new URL(`${API_URL}/admin/registrations/list`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  if (response.status === 201) {
    return (await response.json()) as ApiRegistrationsListResponse;
  }
  return null;
}
