import { Button, Modal, Select } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';
import { apiUsersListAll } from '../../services/api/users';
import { apiAgencyUsersList } from '../../services/api/agency-users';

export interface SelectorRecipient {
  email: string;
  name: string;
  type: string;
}

export interface AnnouncementRecipientSelectorProps {
  onResult?: (recipients: SelectorRecipient[]) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
}

function AnnouncementRecipientSelector({
  onResult,
  disabled = false,
  style,
}: AnnouncementRecipientSelectorProps) {
  const { token } = useSelector(selectUserState);

  const [loadedRecipients, setLoadedRecipients] = React.useState<
    SelectorRecipient[] | null
  >(null);
  const [selectedRecipients, setSelectedRecipients] =
    React.useState<SelectorRecipient[]>();
  const [modalOpened, setModalOpened] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (token) {
      apiUsersListAll(token).then((users) => {
        apiAgencyUsersList({ limit: 9999, offset: 0 }, token).then(
          (agencies) => {
            setLoadedRecipients([
              ...(users?.users.map((user) => ({
                email: user.email,
                name: `${user.firstName} ${user.lastName}`,
                type: 'User',
              })) || []),
              ...(agencies?.agencyUsers.map((user) => ({
                email: user.email,
                name: `${user.firstName} ${user.lastName}`,
                type: 'Agency',
              })) || []),
            ]);
          },
        );
      });
    }
  }, [modalOpened]);

  async function submitUserSelect() {
    if (onResult) {
      onResult(selectedRecipients || []);
    }
    setModalOpened(false);
  }

  return (
    <>
      <Button
        onClick={() => {
          setModalOpened(true);
        }}
        disabled={disabled}
        style={style}
      >
        {selectedRecipients?.length
          ? `Selected (${selectedRecipients.length})`
          : 'Select Recipients'}
      </Button>
      {modalOpened ? (
        <Modal
          title="Accounts"
          open={modalOpened}
          onCancel={() => {
            setModalOpened(false);
          }}
          onOk={submitUserSelect}
          cancelText={'Abort'}
          okText={'Select'}
        >
          <div style={{ marginBottom: 15 }}>
            <Button
              onClick={() => {
                setSelectedRecipients([]);
              }}
              danger
              style={{ marginRight: 15 }}
            >
              Clear All
            </Button>
            <Button
              onClick={() => {
                setSelectedRecipients(loadedRecipients || []);
              }}
            >
              Select All
            </Button>
          </div>
          <Select
            showSearch
            style={{ width: '100%', marginBottom: 45 }}
            placeholder="Select existing"
            loading={loadedRecipients === null}
            value={selectedRecipients?.map((sr) => sr.email)}
            mode="multiple"
            maxTagCount={10}
            onChange={(userEmails: string[]) => {
              const selectedRecipients = loadedRecipients?.filter((u) =>
                userEmails.includes(u.email),
              );
              setSelectedRecipients(selectedRecipients);
            }}
          >
            {loadedRecipients?.map((lf, index) => (
              <Select.Option key={`${lf.email}${index}`} value={lf.email}>
                {`(${lf.type}) ${lf.email}`}
              </Select.Option>
            ))}
          </Select>
        </Modal>
      ) : null}
    </>
  );
}

export default AnnouncementRecipientSelector;
