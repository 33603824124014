import React from 'react';
import { Card, Typography } from 'antd';

function StatisticsPage() {
  return (
    <Card bordered={false} title="Single Option">
      <Typography.Title level={2}>Statistics</Typography.Title>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          marginBottom: 50,
        }}
      >
        <iframe
          width="100%"
          height="1150"
          src="https://lookerstudio.google.com/embed/reporting/8d38dda9-97d2-4f40-afa3-fabe8107fe05/page/xQHOD"
          frameBorder="0"
          style={{ border: 0, maxWidth: '1200px' }}
          allowFullScreen
        ></iframe>
      </div>
      <Typography.Title level={2}>Onboarding Statistics</Typography.Title>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <iframe
          width="100%"
          height="1150"
          src="https://lookerstudio.google.com/embed/reporting/9d6b319e-feed-427a-ad5c-cc16542385d6/page/xQHOD"
          frameBorder="0"
          style={{ border: 0, maxWidth: '1200px' }}
          allowFullScreen
        ></iframe>
      </div>
    </Card>
  );
}

export default StatisticsPage;
