import { API_URL } from './constants';
import {
  ApiFormsCreatePopupRequestBody,
  ApiFormsCreatePopupResponse,
  ApiFormsCreateWizardRequestBody,
  ApiFormsDeletePopupRequestBody,
  ApiFormsDeletePopupResponse,
  ApiFormsGetResponse,
  ApiFormsListResponse,
  ApiFormsUpdateRequestBody,
  ApiFormsUpdateResponse,
} from './types/forms';

export async function apiFormsCreateWizard(
  body: ApiFormsCreateWizardRequestBody,
  token: string,
): Promise<any | null> {
  const url = new URL(`${API_URL}/admin/forms/create-wizard`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [await response.json(), response.status];
}

export async function apiFormsCreatePopup(
  body: ApiFormsCreatePopupRequestBody,
  token: string,
): Promise<[ApiFormsCreatePopupResponse, number]> {
  const url = new URL(`${API_URL}/admin/forms/create-popup`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [await response.json(), response.status];
}

export async function apiFormsDeletePopup(
  body: ApiFormsDeletePopupRequestBody,
  token: string,
): Promise<[ApiFormsDeletePopupResponse, number]> {
  const url = new URL(`${API_URL}/admin/forms/delete-popup`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [await response.json(), response.status];
}

export async function apiFormsList(
  token: string,
  userId?: number,
): Promise<[ApiFormsListResponse, number]> {
  const url = new URL(
    `${API_URL}/admin/forms/list${userId ? `?userId=${userId}` : ''}`,
  ).href;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return [(await response.json()) as ApiFormsListResponse, response.status];
}

export async function apiFormsGet(
  token: string,
  formId?: number,
): Promise<[ApiFormsGetResponse, number]> {
  const url = new URL(
    `${API_URL}/admin/forms/get${formId ? `?formId=${formId}` : ''}`,
  ).href;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return [(await response.json()) as ApiFormsGetResponse, response.status];
}

export async function apiFormsUpdate(
  token: string,
  body: ApiFormsUpdateRequestBody,
): Promise<[ApiFormsUpdateResponse, number]> {
  const url = new URL(`${API_URL}/admin/forms/update`).href;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [(await response.json()) as ApiFormsUpdateResponse, response.status];
}
