import { Button, Card, Form, Input, message, Typography } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import ImageInput from '../../components/ImageInput/imageInput';
import { RouterPaths } from '../../router/paths';
import {
  apiOptionsDelete,
  apiOptionsGet,
  apiOptionsUpdate,
} from '../../services/api/options';
import {
  ApiOptionsGetResponse,
  ApiOptionsUpdateRequestBody,
} from '../../services/api/types/options';
import { selectUserState } from '../../store/selectors/user';
import Select, { DefaultOptionType } from 'antd/es/select';
import { InputType, StepEntity } from '../../services/api/types/steps';

export interface OptionEditFormState {
  value?: string;
  order?: number;
  resultTitle?: string;
  resultDescription?: string;
  resultMinPrice?: number;
  imageId?: number;
  resultImageId?: number;
  nextStep: number | string;
}

function OptionEdit() {
  const navigate = useNavigate();
  const { optionId } = useParams();
  const { token } = useSelector(selectUserState);
  const [optionEditForm] = Form.useForm<OptionEditFormState>();
  const [responseGetOption, setResponseGetOption] =
    React.useState<ApiOptionsGetResponse | null>(null);

  async function loadOptionData(tk: string, oid: number) {
    const [response, status] = await apiOptionsGet(tk, oid);
    if (status !== 200) {
      message.error({ content: 'No option found!' });
    } else {
      setResponseGetOption(response);
      setTimeout(() => {
        optionEditForm.setFieldValue('value', response.data.value);
        optionEditForm.setFieldValue('order', response.data.order);
        optionEditForm.setFieldValue(
          'resultTitle',
          response.data.price ? response.data.price.title : null,
        );
        optionEditForm.setFieldValue(
          'resultDescription',
          response.data.price ? response.data.price.description : null,
        );
        optionEditForm.setFieldValue(
          'resultMinPrice',
          response.data.price ? response.data.price.minPriceRule : null,
        );
        if (
          response.data?.input?.inputType === InputType.SINGLE_SELECT ||
          response.data?.input?.inputType === InputType.MULTIPLE_SELECT
        ) {
          optionEditForm.setFieldValue(
            'imageId',
            response.data.image ? response.data.image.id : null,
          );
        }
        optionEditForm.setFieldValue(
          'resultImageId',
          response.data.price?.image ? response.data.price.image.id : null,
        );
        optionEditForm.setFieldValue(
          'nextStep',
          response.data.nextStep ? response.data.nextStep.id : '',
        );
      }, 0);
    }
  }

  const userEditSubmitCallback = React.useCallback(
    async (values: OptionEditFormState) => {
      if (responseGetOption && token) {
        const updateBodyRequest: ApiOptionsUpdateRequestBody = {
          optionId: responseGetOption.data.id,
        };
        const nextStepParsed: number | null =
          typeof values.nextStep !== 'number' ? null : values.nextStep;
        let shouldUpdate = false;
        if (values.value !== responseGetOption.data.value) {
          updateBodyRequest.value = values.value;
          shouldUpdate = true;
        }
        if (
          parseInt(values.order as unknown as string, 10) !==
          responseGetOption.data.order
        ) {
          updateBodyRequest.order = parseInt(
            values.order as unknown as string,
            10,
          );
          shouldUpdate = true;
        }
        if (values.resultTitle !== responseGetOption.data.price?.title) {
          updateBodyRequest.resultTitle = values.resultTitle || null;
          shouldUpdate = true;
        }
        if (
          values.resultDescription !== responseGetOption.data.price?.description
        ) {
          updateBodyRequest.resultDescription =
            values.resultDescription || null;
          shouldUpdate = true;
        }
        if (
          parseInt(values.resultMinPrice as unknown as string, 10) !==
          responseGetOption.data.price?.minPriceRule
        ) {
          updateBodyRequest.resultMinPrice =
            parseInt(values.resultMinPrice as unknown as string, 10) || null;
          shouldUpdate = true;
        }
        if (values.imageId !== responseGetOption.data.image?.id) {
          updateBodyRequest.imageId = values.imageId || null;
          shouldUpdate = true;
        }
        if (values.resultImageId !== responseGetOption.data.price?.image?.id) {
          updateBodyRequest.resultImageId = values.resultImageId || null;
          shouldUpdate = true;
        }
        if (values.resultImageId !== responseGetOption.data.price?.image?.id) {
          updateBodyRequest.resultImageId = values.resultImageId || null;
          shouldUpdate = true;
        }
        if (nextStepParsed !== (responseGetOption.data.nextStep?.id || null)) {
          updateBodyRequest.nextStepId = nextStepParsed;
          shouldUpdate = true;
        }

        if (shouldUpdate) {
          const [response, status] = await apiOptionsUpdate(
            token,
            updateBodyRequest,
          );
          if (status === 200) {
            message.success({ content: 'Option updated successfully!' });
            loadOptionData(token, response.data.id);
          } else {
            message.error({
              content: (response as any)?.message || 'Failed user update!',
            });
          }
        }
      }
    },
    [responseGetOption, token],
  );

  React.useEffect(() => {
    if (token && optionId) {
      loadOptionData(token, parseInt(optionId, 10));
    }
  }, [optionId, token]);

  if (!optionId) {
    message.error('Missing optionId in url!');
    navigate('/');
    return null;
  }

  return (
    <Card bordered={false} title="Single Option">
      <Typography.Title level={2}>Option Edit</Typography.Title>
      {responseGetOption ? (
        <>
          <Form form={optionEditForm} onFinish={userEditSubmitCallback}>
            <Form.Item
              label="Value"
              name="value"
              rules={[
                {
                  required: true,
                  message: 'Please input option value!',
                  max: 512,
                },
              ]}
              initialValue={responseGetOption.data.value}
            >
              <Input placeholder="Yes" />
            </Form.Item>
            <Form.Item
              label="Order"
              name="order"
              rules={[
                {
                  required: true,
                  message: 'Please input option order!',
                },
              ]}
              initialValue={responseGetOption.data.order}
            >
              <Input type="number" />
            </Form.Item>
            {responseGetOption?.data?.input?.inputType ===
              InputType.SINGLE_SELECT ||
            responseGetOption?.data?.input?.inputType ===
              InputType.MULTIPLE_SELECT ? (
              <ImageInput
                name="imageId"
                label="Option Image"
                userId={responseGetOption.data.user?.id}
                defaultImageId={responseGetOption.data.image?.id}
              />
            ) : null}
            <Form.Item
              label="Result Title"
              name="resultTitle"
              rules={[
                {
                  required: false,
                  message: 'Max length 512!',
                  max: 512,
                },
              ]}
              initialValue={responseGetOption.data.price?.title}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Result Description"
              name="resultDescription"
              rules={[
                {
                  required: false,
                  message: 'Max length 512!',
                  max: 512,
                },
              ]}
              initialValue={responseGetOption.data.price?.description}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Result Min Price in cents"
              name="resultMinPrice"
              rules={[
                {
                  required: false,
                },
              ]}
              initialValue={responseGetOption.data.price?.minPriceRule}
            >
              <Input type="number" />
            </Form.Item>
            <ImageInput
              name="resultImageId"
              label="Result Image"
              userId={responseGetOption.data.user?.id}
              required={false}
              defaultImageId={responseGetOption.data.price?.image?.id}
            />
            <Form.Item
              label="Next Step"
              name="nextStep"
              initialValue={responseGetOption.data.nextStep?.id || ''}
            >
              <Select
                options={
                  responseGetOption?.data
                    ? responseGetOption?.data?.input?.step?.form?.steps
                        ?.map<DefaultOptionType>((step: StepEntity) => ({
                          value: step.id,
                          label: step.title,
                        }))
                        .concat([{ label: 'No Next Step', value: '' }])
                    : []
                }
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Update Option
              </Button>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            style={{ marginBottom: '45px' }}
            danger
            onClick={async () => {
              if (token && responseGetOption.data?.input?.step?.id) {
                const [, status] = await apiOptionsDelete(
                  token,
                  responseGetOption.data.id,
                );
                if (status !== 200) {
                  message.error('Failed to delete option!');
                } else {
                  message.success('Successfully deleted option!');
                  navigate(
                    `${RouterPaths.STEPS}/${responseGetOption.data?.input?.step?.id}`,
                  );
                }
              }
            }}
          >
            Delete Option
          </Button>
        </>
      ) : (
        'Loading'
      )}
    </Card>
  );
}

export default OptionEdit;
