import { Button, Card, Form, Input, Typography, message } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { apiFormsGet, apiFormsUpdate } from '../../services/api/forms';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';
import {
  ApiFormsGetResponse,
  ApiFormsUpdateRequestBody,
} from '../../services/api/types/forms';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';

interface FormOriginsState {
  origins: string[];
}

function FormOriginsEditPage() {
  const { token } = useSelector(selectUserState);
  const { formId } = useParams();
  const navigate = useNavigate();
  const [responseGetForm, setResponseGetForm] =
    React.useState<ApiFormsGetResponse | null>(null);
  const [formOriginsForm] = Form.useForm<FormOriginsState>();

  async function loadFormData(tk: string, fid: number) {
    const [response, status] = await apiFormsGet(tk, fid);
    if (status === 200) {
      setResponseGetForm(response);
      formOriginsForm.setFieldValue(
        'origins',
        response.data.origins?.map((or) => or.origin),
      );
    } else {
      message.error({ content: 'Error loading form!' });
      navigate('/');
    }
  }

  React.useEffect(() => {
    if (token && formId) {
      loadFormData(token, parseInt(formId, 10));
    }
  }, [token, formId]);

  const formOriginsSubmit = React.useCallback(
    async (values: FormOriginsState) => {
      if (responseGetForm && token && values && Object.keys(values).length) {
        const updateFormRequestBody: ApiFormsUpdateRequestBody = {
          formId: responseGetForm.data.id,
          origins: values.origins,
        };
        const [response, status] = await apiFormsUpdate(
          token,
          updateFormRequestBody,
        );
        if (status === 200) {
          message.success({ content: 'Form updated successfully!' });
          loadFormData(token, response.data.id);
        } else {
          message.error({ content: 'Failed form update!' });
        }
      }
    },
    [token, responseGetForm],
  );

  if (!formId) {
    message.error('Missing formId in url!');
    navigate('/');
    return null;
  }

  return (
    <Card bordered={false} title="Single Form">
      <Typography.Title level={2}>Form Origins Edit</Typography.Title>
      <Form form={formOriginsForm} onFinish={formOriginsSubmit}>
        <Form.List name="origins">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item required={false} key={field.key}>
                  <Form.Item
                    {...field}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: 'Please input origin url',
                      },
                    ]}
                    noStyle
                  >
                    <Input
                      placeholder="https://example.com/"
                      style={{ width: '60%' }}
                    />
                  </Form.Item>
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => {
                      remove(field.name);
                    }}
                    style={{ height: 50, marginLeft: 20, fontSize: 20 }}
                  />
                </Form.Item>
              ))}
              <Button
                type="dashed"
                onClick={() => {
                  add();
                }}
                icon={<PlusOutlined />}
              >
                Add origin
              </Button>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ marginTop: 55 }}>
            Update Origins
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default FormOriginsEditPage;
