/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react';
import { Card, Table, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';
import { useNavigate } from 'react-router';
import { RouterPaths } from '../../router/paths';
import {
  AdminFeedbackEntity,
  FeedbackEntity,
} from '../../services/api/types/feedbacks';
import {
  apiFeedbacksList,
  apiFeedbacksListActiveTypes,
} from '../../services/api/feedbacks';

interface DataType extends AdminFeedbackEntity {
  key: string;
}

interface DataTypeActiveTypes extends FeedbackEntity {
  key: string;
}

const PER_PAGE_LIMIT = 10;

function FeedbacksPage() {
  const [offset, setOffset] = React.useState(0);
  const [offsetAT, setOffsetAT] = React.useState(0);
  const [dataSource, setDataSource] = React.useState<
    [DataType[], number] | null
  >(null);
  const [dataSourceActiveTypes, setDataSourceActiveTypes] = React.useState<
    [DataTypeActiveTypes[], number] | null
  >(null);
  const { token } = useSelector(selectUserState);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (token) {
      (async () => {
        const response = await apiFeedbacksList(
          { offset, limit: PER_PAGE_LIMIT },
          token,
        );
        if (response) {
          if (dataSource) {
            setDataSource([
              [
                ...dataSource[0],
                ...response.feedbacks.map((fd) => ({
                  ...fd,
                  key: fd.id,
                })),
              ],
              response.total,
            ]);
          } else {
            setDataSource([
              response.feedbacks.map((fd) => ({ ...fd, key: fd.id })),
              response.total,
            ]);
          }
          setDataSource([
            response.feedbacks.map((fd) => ({ ...fd, key: fd.id })),
            response.total,
          ]);
        } else {
          setDataSource([[], 0]);
        }
      })();
    }
  }, [offset]);

  React.useEffect(() => {
    if (token) {
      (async () => {
        const response = await apiFeedbacksListActiveTypes(
          { offset: offsetAT, limit: PER_PAGE_LIMIT },
          token,
        );
        if (response) {
          if (dataSourceActiveTypes) {
            setDataSourceActiveTypes([
              [
                ...dataSourceActiveTypes[0],
                ...response.feedbacks.map((fd) => ({
                  ...fd,
                  key: fd.id.toString(),
                })),
              ],
              response.total,
            ]);
          } else {
            setDataSourceActiveTypes([
              response.feedbacks.map((fd) => ({
                ...fd,
                key: fd.id.toString(),
              })),
              response.total,
            ]);
          }
          setDataSourceActiveTypes([
            response.feedbacks.map((fd) => ({ ...fd, key: fd.id.toString() })),
            response.total,
          ]);
        } else {
          setDataSourceActiveTypes([[], 0]);
        }
      })();
    }
  }, [offsetAT]);

  const columns = React.useMemo<ColumnsType<DataType>>(
    () => [
      {
        key: 'id',
        title: 'ID',
        dataIndex: 'id',
        render: (v) => `${v}`,
        fixed: true,
      },
      {
        key: 'userId',
        title: 'User ID',
        dataIndex: 'userId',
        render: (v) => `${v}`,
        fixed: true,
      },
      {
        key: 'userEmail',
        title: 'User Email',
        dataIndex: 'userEmail',
        render: (v) => `${v}`,
        fixed: true,
      },
      {
        key: 'feedbackType',
        title: 'Feedback Type',
        dataIndex: 'feedbackType',
        fixed: false,
        render: (v) => `${v}`,
      },
      {
        key: 'feedbackId',
        title: 'Feedback ID',
        dataIndex: 'feedbackId',
        render: (v) => `${v}`,
        fixed: false,
      },
      {
        key: 'createdAt',
        title: 'Created at',
        dataIndex: 'createdAt',
        render: (v) => `${v}`,
      },
    ],
    [],
  );

  const columnsAT = React.useMemo<ColumnsType<DataTypeActiveTypes>>(
    () => [
      {
        key: 'id',
        title: 'ID',
        dataIndex: 'id',
        render: (v) => `${v}`,
        fixed: true,
      },
      {
        key: 'order',
        title: 'Order',
        dataIndex: 'order',
        render: (v) => `${v}`,
        fixed: true,
      },
      {
        key: 'type',
        title: 'Feedback Type',
        dataIndex: 'type',
        render: (v) => `${v}`,
        fixed: false,
      },
      {
        key: 'triggerType',
        title: 'Feedback Trigger Type',
        dataIndex: 'triggerType',
        render: (v) => `${v}`,
        fixed: false,
      },
      {
        key: 'triggerTime',
        title: 'Trigger Value',
        dataIndex: 'triggerTime',
        fixed: false,
        render: (v) => `${v}`,
      },
    ],
    [],
  );

  return (
    <Card bordered={false} title="Feedbacks">
      <Typography.Title level={2}>Active Feedbacks</Typography.Title>
      <Table
        scroll={{ x: true }}
        dataSource={dataSourceActiveTypes ? dataSourceActiveTypes[0] : []}
        loading={!dataSourceActiveTypes}
        columns={columnsAT}
        pagination={{
          total: dataSourceActiveTypes ? dataSourceActiveTypes[1] : 0,
        }}
        onChange={(pagination) => {
          if (pagination.current) {
            setOffsetAT(10 * (pagination.current - 1));
          }
        }}
        style={{ marginBottom: 30 }}
      />
      <Typography.Title level={2}>Submitted Feedbacks</Typography.Title>
      <Table
        scroll={{ x: true }}
        dataSource={dataSource ? dataSource[0] : []}
        loading={!dataSource}
        columns={columns}
        pagination={{
          total: dataSource ? dataSource[1] : 0,
        }}
        onRow={(record) => ({
          onClick: () => {
            navigate(`${RouterPaths.FEEDBACKS}/${record.id}`);
          },
        })}
        onChange={(pagination) => {
          if (pagination.current) {
            setOffset(10 * (pagination.current - 1));
          }
        }}
      />
    </Card>
  );
}

export default FeedbacksPage;
