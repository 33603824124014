import { FormNewFormState, StepsFormState } from '..';
import { InputType } from '../../../services/api/types/steps';

export const LandscapingWizardTemplate: {
  formF: FormNewFormState;
  stepsF: StepsFormState;
} = {
  formF: {
    name: 'Example Landscaping Form',
    slug: 'example-landscaping-form',
    brandColor: '189ED9',
    origin: 'https://example.com/',
    widgetTitle: 'Get estimate for free',
    widgetButtonText: 'Start estimation',
    logoImageId: null as any,
    developmentMode: false,
    createDefaultPopup: true,
    requirePhone: true,
    requireAdditionalNotes: true,
    requireAddress: true,
    linkable: true,
    resultScreenButtonRedirect: null,
    resultScreenRedirect: null,
  },
  stepsF: {
    steps: [
      {
        title: 'What kind of project do you have in mind?',
        order: 0,
        isFinalStep: false,
        submitButtonText: 'Continue',
        type: InputType.SINGLE_SELECT,
        nextQuestionIndex: 1,
        dataAnnotation: null,
        options: [
          {
            value: 'Landscaping',
            order: 0,
            resultTitle: 'Landscaping Result Title',
            resultDescription: 'Landscaping Result Description',
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Artificial grass',
            order: 1,
            resultTitle: 'Artificial Grass Result Title',
            resultDescription: 'Artificial Grass Result Description',
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Consultation',
            order: 2,
            resultTitle: 'Consultation Result Title',
            resultDescription: 'Consultation Result Description',
            imageId: null as any,
            nextQuestionIndex: null,
          },
        ],
      },
      {
        title: 'Which type of property requires the service?',
        order: 1,
        isFinalStep: false,
        submitButtonText: 'Continue',
        type: InputType.SINGLE_SELECT,
        nextQuestionIndex: 2,
        dataAnnotation: null,
        options: [
          {
            value: 'Residential',
            order: 0,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Commercial',
            order: 1,
            imageId: null as any,
            nextQuestionIndex: null,
          },
        ],
      },
      {
        title: 'What is the size of the property?',
        order: 2,
        isFinalStep: false,
        submitButtonText: 'Continue',
        type: InputType.SINGLE_SELECT,
        nextQuestionIndex: 3,
        dataAnnotation: null,
        options: [
          {
            value: 'Small',
            order: 0,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Medium',
            order: 1,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Large',
            order: 2,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Very Large',
            order: 3,
            imageId: null as any,
            nextQuestionIndex: null,
          },
        ],
      },
      {
        title: 'When would you like this service to be provided?',
        order: 3,
        isFinalStep: false,
        submitButtonText: 'Continue',
        type: InputType.SINGLE_SELECT,
        nextQuestionIndex: 4,
        dataAnnotation: null,
        options: [
          {
            value: 'As soon as possible',
            order: 0,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: `I'm flexible`,
            order: 1,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Planning and budgeting for future',
            order: 2,
            imageId: null as any,
            nextQuestionIndex: null,
          },
        ],
      },
      {
        title:
          'Alright, you are one step away from your free instant estimate!',
        order: 4,
        isFinalStep: true,
        submitButtonText: 'Submit For An Estimation',
        type: null,
        options: undefined as any,
        nextQuestionIndex: null,
        dataAnnotation: null,
      },
    ],
  },
};
