import React from 'react';
import { API_URL } from '../../services/api/constants';

export interface ImageProps {
  id: number;
  style?: React.CSSProperties;
  className?: string;
}

function Image({ id, style, className = '' }: ImageProps) {
  return (
    <img
      src={`${API_URL}/api/images/download?imageId=${id}`}
      style={{
        width: '70px',
        objectFit: 'contain',
        ...style,
      }}
      className={className}
    />
  );
}

export default Image;
