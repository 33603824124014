import { Button, Card, Form, Input, message, Select, Switch } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { selectUserState } from '../../store/selectors/user';
import { RouterPaths } from '../../router/paths';
import Timezone from 'timezone-enum';
import { apiAgencyUsersCreate } from '../../services/api/agency-users';
import generator from 'generate-password-browser';

export interface AgencyUserCreateFormState {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  active: boolean;
  activeEmailNotifications: boolean;
  password: string;
  timezone: Timezone;
}

function AgencyUserCreatePage() {
  const [stepCreateForm] = Form.useForm<AgencyUserCreateFormState>();
  const navigate = useNavigate();
  const { token } = useSelector(selectUserState);

  const createAgencyUserCallback = React.useCallback(
    async (values: AgencyUserCreateFormState) => {
      if (token) {
        const res = await apiAgencyUsersCreate(
          {
            ...values,
          },
          token,
        );
        if (!res) {
          message.error('Error creating agency!');
        } else {
          message.success('Successfully created agency!');
          navigate(`${RouterPaths.AGENCY_USERS}`);
        }
      }
    },
    [token],
  );

  return (
    <Card bordered={false} title="Agency Create">
      <Form form={stepCreateForm} onFinish={createAgencyUserCallback}>
        <Form.Item
          label="First Name"
          name="firstName"
          rules={[
            {
              required: true,
              message: 'Please input first name!',
              max: 100,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[
            { required: true, message: 'Please input last name!', max: 100 },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            { required: true, message: 'Please input email!', max: 255 },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Phone number"
          name="phoneNumber"
          rules={[
            {
              required: false,
              message: 'Please input phone number!',
              max: 255,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            { required: false, message: 'Please input password!', max: 255 },
          ]}
          initialValue={generator.generate({ length: 8, numbers: true })}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Active Email Notifications"
          name="activeEmailNotifications"
          valuePropName="checked"
          initialValue={true}
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label="Timezone"
          name="timezone"
          initialValue={Timezone['US/Central']}
        >
          <Select>
            {Object.values(Timezone).map((tz) => (
              <Select.Option key={tz} value={tz}>
                {tz}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create agency
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default AgencyUserCreatePage;
