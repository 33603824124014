/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react';
import { Card, List, Typography, message } from 'antd';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';
import { useNavigate, useParams } from 'react-router';
import { RouterPaths } from '../../router/paths';
import { AdminFeedbackAnswer } from '../../services/api/types/feedbacks';
import { apiFeedbacksGet } from '../../services/api/feedbacks';

interface DataType extends AdminFeedbackAnswer {
  key: string;
}

function SingleFeedbackPage() {
  const [dataSource, setDataSource] = React.useState<DataType[] | null>(null);
  const { token } = useSelector(selectUserState);
  const { feedbackId } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (token && feedbackId) {
      (async () => {
        const [response] = await apiFeedbacksGet(feedbackId, token);
        if (response) {
          setDataSource([
            ...response.answers.map((fd) => ({
              ...fd,
              key: fd.id.toString(),
            })),
          ]);
        } else {
          setDataSource([]);
        }
      })();
    }
  }, [feedbackId]);

  if (!feedbackId) {
    navigate(RouterPaths.FEEDBACKS);
    message.error('Feedback submit does not exist!');
  }

  return (
    <Card bordered={false} title="Single Feedback">
      {dataSource ? (
        <List
          bordered
          dataSource={dataSource}
          renderItem={(item) => (
            <List.Item
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Typography.Text strong>{item.question}:</Typography.Text>{' '}
              {item.answer}
            </List.Item>
          )}
        />
      ) : null}
    </Card>
  );
}

export default SingleFeedbackPage;
