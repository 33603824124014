import { Button, Card, Form, Input, Typography, message } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { apiFormsGet, apiFormsUpdate } from '../../services/api/forms';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';
import {
  ApiFormsGetResponse,
  ApiFormsUpdateRequestBody,
  FormEntityTranslations,
} from '../../services/api/types/forms';

function TranslationsEdit() {
  const { token } = useSelector(selectUserState);
  const { formId } = useParams();
  const navigate = useNavigate();
  const [responseGetForm, setResponseGetForm] =
    React.useState<ApiFormsGetResponse | null>(null);
  const [formEditTranslations] = Form.useForm<FormEntityTranslations>();

  async function loadFormData(tk: string, fid: number) {
    const [response, status] = await apiFormsGet(tk, fid);
    if (status === 200) {
      setResponseGetForm(response);
    } else {
      message.error({ content: 'Error loading form!' });
      navigate('/');
    }
  }

  React.useEffect(() => {
    if (token && formId) {
      loadFormData(token, parseInt(formId, 10));
    }
  }, [token, formId]);

  const formTranslationsEditSubmitCallback = React.useCallback(
    async (values: FormEntityTranslations) => {
      if (responseGetForm && token && values && Object.keys(values).length) {
        const updateFormRequestBody: ApiFormsUpdateRequestBody = {
          formId: responseGetForm.data.id,
          translations: values,
        };
        const [response, status] = await apiFormsUpdate(
          token,
          updateFormRequestBody,
        );
        if (status === 200) {
          message.success({ content: 'Form updated successfully!' });
          loadFormData(token, response.data.id);
        } else {
          message.error({ content: 'Failed form update!' });
        }
      }
    },
    [token, responseGetForm],
  );

  if (!formId) {
    message.error('Missing formId in url!');
    navigate('/');
    return null;
  }

  return (
    <Card bordered={false} title="Single Form">
      <Typography.Title level={2}>Translations Edit</Typography.Title>
      <Form
        form={formEditTranslations}
        onFinish={formTranslationsEditSubmitCallback}
      >
        {responseGetForm?.data.translations
          ? Object.keys(responseGetForm?.data.translations).map((trKey) => {
              return (
                <Form.Item
                  key={trKey}
                  label={trKey}
                  name={trKey}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter translation!',
                      max: 5000,
                    },
                  ]}
                  initialValue={
                    (responseGetForm?.data.translations as any)[trKey]
                  }
                >
                  <Input />
                </Form.Item>
              );
            })
          : null}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Update Translations
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default TranslationsEdit;
