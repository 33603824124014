import { ReduxActionPayload } from '../actions/types';
import { ActionTypes } from '../actions/types/type.actions';
import { ActionUserLoginPayload } from '../actions/types/user';
import { UserState } from './types/user';

const defaultState: UserState = {
  isLoggedIn: false,
  token: null,
};

function userReducer(
  state = defaultState,
  action: ReduxActionPayload,
): UserState {
  switch (action.type) {
    case ActionTypes.USER_LOGIN: {
      const payload = action.payload as ActionUserLoginPayload;
      return {
        isLoggedIn: true,
        token: payload.token,
      };
    }
    case ActionTypes.USER_LOGOUT: {
      return {
        isLoggedIn: false,
        token: null,
      };
    }
    default:
      return state;
  }
}

export default userReducer;
