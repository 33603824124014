import { Button, Modal, Select } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';
import { apiMailListSenders } from '../../services/api/mail';
import { MailApiListSendersResponse } from '../../services/api/types/mail';

export interface SenderSelectorComponentProps {
  onResult?: (senderEmail: string) => void;
  disabled?: boolean;
  defaultSelected?: string | null;
  style?: React.CSSProperties;
}

function SenderSelectorComponent({
  onResult,
  disabled = false,
  defaultSelected = null,
  style,
}: SenderSelectorComponentProps) {
  const { token } = useSelector(selectUserState);

  const [loadedSenders, setLoadedSenders] =
    React.useState<MailApiListSendersResponse | null>(null);
  const [selectedSender, setSelectedSender] = React.useState<string | null>(
    defaultSelected,
  );
  const [modalOpened, setModalOpened] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (token) {
      apiMailListSenders(token).then((v) => {
        setLoadedSenders(v);
      });
    }
  }, [modalOpened]);

  async function submitUserSelect() {
    if (onResult && selectedSender) {
      onResult(selectedSender);
    }
    setModalOpened(false);
  }

  return (
    <>
      <Button
        onClick={() => {
          setModalOpened(true);
        }}
        disabled={disabled}
        style={style}
      >
        {selectedSender || 'Select Email Sender'}
      </Button>
      {modalOpened ? (
        <Modal
          title="Tapform Email Senders"
          open={modalOpened}
          onCancel={() => {
            setModalOpened(false);
          }}
          onOk={submitUserSelect}
          cancelText={'Abort'}
          okText={'Select'}
        >
          <Select
            showSearch
            style={{ width: '100%', marginBottom: 45 }}
            placeholder="Select existing"
            loading={loadedSenders === null}
            defaultValue={selectedSender}
            onChange={(userEmail) => {
              setSelectedSender(userEmail);
            }}
          >
            {loadedSenders?.data?.senders?.map((lf, index) => (
              <Select.Option key={`${lf}${index}`} value={lf}>
                {lf}
              </Select.Option>
            ))}
          </Select>
        </Modal>
      ) : null}
    </>
  );
}

export default SenderSelectorComponent;
