import { FormEntity } from './forms';
import { OptionEntity } from './options';

export enum InputType {
  SINGLE_SELECT = 'single-select',
  SINGLE_SELECT_NO_ICON = 'single-select-no-icon',
  MULTIPLE_SELECT = 'multiple-select',
  MULTIPLE_SELECT_NO_ICON = 'multiple-select-no-icon',
  PHONE_INPUT = 'phone-input',
  TEXT_INPUT = 'text-input',
  EMAIL_INPUT = 'email-input',
}

export const ALLOWED_INPUT_TYPES = [
  InputType.SINGLE_SELECT,
  InputType.TEXT_INPUT,
  InputType.SINGLE_SELECT_NO_ICON,
  InputType.MULTIPLE_SELECT,
  InputType.MULTIPLE_SELECT_NO_ICON,
];

export interface InputEntity {
  id: number;
  createdAt: string;
  modifiedAt: string;
  deletedAt: null;
  question: string;
  order: number;
  inputType: InputType;
  options?: OptionEntity[];
  step?: StepEntity;
  dataAnnotation: string | null;
  inputLabel: string | null;
}

export interface StepEntity {
  id: number;
  createdAt: string;
  modifiedAt: string;
  deletedAt: null;
  title: string;
  description: null;
  isFinalStep: boolean;
  order: number;
  submitButtonText: string;
  stepType: string;
  inputs?: InputEntity[];
  form?: FormEntity;
  nextStep?: StepEntity;
}

export interface ApiStepsGetResponse {
  data: StepEntity;
}

export interface ApiStepsUpdateRequestBody {
  stepId: number;
  title?: string;
  order?: number;
  submitButtonText?: string;
  isFinalStep?: boolean;
  nextStepId?: number | null;
  inputLabel?: string;
  inputType?: InputType;
  dataAnnotation?: string | null;
}

export interface ApiStepsUpdateResponse {
  data: StepEntity;
}

export interface ApiStepsCreateRequestBody {
  formId: number;
  isFinalStep: boolean;
  order: number;
  submitButtonText: string;
  title: string;
  options: {
    imageId: number;
    order: number;
    value: string;
    resultDescription?: string;
    resultTitle?: string;
    resultMinPrice?: number;
    resultImageId?: number;
  }[];
  inputLabel?: string;
  inputType: InputType;
}

export interface ApiStepsCreateResponse {
  data: StepEntity;
}

export interface ApiStepsDeleteResponse {
  data: StepEntity;
}
