import { API_URL } from './constants';
import {
  AdminFeedbackEntity,
  ApiFeedbacksListActiveTypesRequestBody,
  ApiFeedbacksListActiveTypesResponse,
  ApiFeedbacksListRequestBody,
  ApiFeedbacksListResponse,
} from './types/feedbacks';

export async function apiFeedbacksList(
  body: ApiFeedbacksListRequestBody,
  token: string,
): Promise<ApiFeedbacksListResponse | null> {
  const url = new URL(`${API_URL}/admin/feedbacks/list`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  if (response.status === 201) {
    return (await response.json()) as ApiFeedbacksListResponse;
  }
  return null;
}

export async function apiFeedbacksGet(
  feedbackId: string,
  token: string,
): Promise<[AdminFeedbackEntity | null, number]> {
  const url = new URL(`${API_URL}/admin/feedbacks/get?feedbackId=${feedbackId}`)
    .href;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return [(await response.json()) as AdminFeedbackEntity, response.status];
}

export async function apiFeedbacksListActiveTypes(
  body: ApiFeedbacksListActiveTypesRequestBody,
  token: string,
): Promise<ApiFeedbacksListActiveTypesResponse | null> {
  const url = new URL(`${API_URL}/admin/feedbacks/list-active-types`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  if (response.status === 201) {
    return (await response.json()) as ApiFeedbacksListActiveTypesResponse;
  }
  return null;
}
