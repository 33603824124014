import { Button, Form, Input, message } from 'antd';
import Card from 'antd/es/card/Card';
import React from 'react';
import { useDispatch } from 'react-redux';
import { apiAuthLogin } from '../../services/api/auth';
import { actionUserLogin } from '../../store/actions/user.actions';
import './index.scss';

function LoginPage() {
  const dispatch = useDispatch();
  const loginCallback = React.useCallback(async (values: any) => {
    if (values.username?.length && values.password?.length) {
      const result = await apiAuthLogin(values);
      if (result) {
        dispatch(actionUserLogin({ token: result.access_token }));
        message.success({ content: 'Logged in!' });
      } else {
        message.error({ content: 'Incorrect login!' });
      }
    } else {
      message.error({ content: 'Must not be empty!' });
    }
  }, []);

  return (
    <div className="login-page-container">
      <Card title="Login">
        <Form name="login" onFinish={loginCallback}>
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}

export default LoginPage;
