/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react';
import { Button, Card, Form, Input } from 'antd';
import {
  ApiImagesListResponse,
  ApiImagesListResponseImage,
} from '../../services/api/types/images';
import { apiImagesList } from '../../services/api/images';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';
import Table, { ColumnsType } from 'antd/es/table';
import bytes from 'bytes';
import Image from '../Image';
import ImageUpload from '../ImageUpload';
import { CloseOutlined } from '@ant-design/icons';

export interface FileManagerProps {
  userId?: number;
  visible: boolean;
  onFileSelect?: (id: number) => void;
  onClose?: () => void;
}

interface DataType extends ApiImagesListResponseImage {
  key: string;
}

function FileManager({
  userId,
  visible,
  onFileSelect,
  onClose,
}: FileManagerProps) {
  const { token } = useSelector(selectUserState);
  const [userIdSelected, setUserId] = React.useState<number | null>(
    userId || null,
  );
  const [imageResponse, setImageResponse] =
    React.useState<ApiImagesListResponse | null>(null);

  async function loadImages(uid: number, tok: string) {
    const response = await apiImagesList(uid, tok);
    setImageResponse(response);
  }
  React.useEffect(() => {
    if (visible && token && userIdSelected) {
      loadImages(userIdSelected, token);
    }
  }, [visible, token, userIdSelected]);

  const columns = React.useMemo<ColumnsType<DataType>>(
    () => [
      {
        key: 'image',
        title: 'Image',
        dataIndex: 'id',
        render: (v) => <Image key={v} style={{ width: '70px' }} id={v} />,
      },
      {
        key: 'id',
        title: 'ID',
        dataIndex: 'id',
        render: (v) => `${v}`,
      },
      {
        key: 'size',
        title: 'Size in Bytes',
        dataIndex: 'size',
        render: (v) => bytes(v),
      },
    ],
    [],
  );

  React.useEffect(() => {
    if (userId) {
      setUserId(userId);
    }
  }, [userId]);

  if (!visible) {
    return null;
  }
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        zIndex: '10',
        position: 'fixed',
        left: 0,
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backdropFilter: 'blur(4px)',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        padding: '20px 0',
      }}
    >
      <Card
        style={{ width: '1000px', maxHeight: '100%', overflowY: 'scroll' }}
        title={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span>File Manager</span>
            <span
              style={{ cursor: 'pointer', padding: '10px' }}
              onClick={() => {
                if (onClose) {
                  onClose();
                }
              }}
            >
              <CloseOutlined />
            </span>
          </div>
        }
      >
        {!userIdSelected ? (
          <>
            <Form
              onFinish={(values) => {
                setUserId(values.userId);
              }}
            >
              <Form.Item
                label="User ID"
                name="userId"
                rules={[{ required: true, message: 'Please input user ID!' }]}
              >
                <Input type="number" />
              </Form.Item>
              <Button type="primary" htmlType="submit">
                Select
              </Button>
            </Form>
          </>
        ) : (
          <>
            <Table
              dataSource={
                imageResponse
                  ? imageResponse.data.images.map((usr) => ({
                      ...usr,
                      key: usr.id,
                    }))
                  : []
              }
              columns={columns as any}
              pagination={false}
              onRow={(record) => ({
                onClick: () => {
                  if (onFileSelect) {
                    onFileSelect(record.id);
                  }
                },
              })}
              style={{ marginBottom: '20px' }}
            />
            <ImageUpload
              userId={userIdSelected}
              onUpload={() => {
                if (token) {
                  loadImages(userIdSelected, token);
                }
              }}
            />
          </>
        )}
      </Card>
    </div>
  );
}

export default FileManager;
