import { message } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import React from 'react';
import { useSelector } from 'react-redux';
import { API_URL } from '../../services/api/constants';
import { selectUserState } from '../../store/selectors/user';
import { InboxOutlined } from '@ant-design/icons';

export interface ImageUploadProps {
  userId: number;
  onUpload?: () => void;
}

function ImageUpload({ userId, onUpload }: ImageUploadProps) {
  const { token } = useSelector(selectUserState);

  return (
    <Dragger
      name="file"
      multiple={false}
      accept={'.jpg,.png'}
      action={`${API_URL}/admin/images/upload?userId=${userId}`}
      onChange={(info) => {
        const { status } = info.file;
        if (status === 'done') {
          message.success(`${info.file.name} file uploaded successfully.`);
          if (onUpload) {
            onUpload();
          }
        } else if (status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      }}
      headers={{
        Authorization: `Bearer ${token as string}`,
      }}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
      <p className="ant-upload-hint">Must be .png or .jpg only!</p>
    </Dragger>
  );
}

export default ImageUpload;
