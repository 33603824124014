import { API_URL } from './constants';
import {
  ApiOptionsCreateRequestBody,
  ApiOptionsCreateResponse,
  ApiOptionsDeleteResponse,
  ApiOptionsGetResponse,
  ApiOptionsUpdateRequestBody,
  ApiOptionsUpdateResponse,
} from './types/options';

export async function apiOptionsGet(
  token: string,
  optionId?: number,
): Promise<[ApiOptionsGetResponse, number]> {
  const url = new URL(
    `${API_URL}/admin/options/get${optionId ? `?optionId=${optionId}` : ''}`,
  ).href;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return [(await response.json()) as ApiOptionsGetResponse, response.status];
}

export async function apiOptionsUpdate(
  token: string,
  body: ApiOptionsUpdateRequestBody,
): Promise<[ApiOptionsUpdateResponse, number]> {
  const url = new URL(`${API_URL}/admin/options/update`).href;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [(await response.json()) as ApiOptionsUpdateResponse, response.status];
}

export async function apiOptionsCreate(
  token: string,
  body: ApiOptionsCreateRequestBody,
): Promise<[ApiOptionsCreateResponse, number]> {
  const url = new URL(`${API_URL}/admin/options/create`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [(await response.json()) as ApiOptionsCreateResponse, response.status];
}

export async function apiOptionsDelete(
  token: string,
  optionId: number,
): Promise<[ApiOptionsDeleteResponse, number]> {
  const url = new URL(`${API_URL}/admin/options/delete?optionId=${optionId}`)
    .href;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return [(await response.json()) as ApiOptionsDeleteResponse, response.status];
}
