import { ReduxActionPayload } from './types';
import { ActionTypes } from './types/type.actions';
import { ActionUserLoginPayload } from './types/user';

export function actionUserLogin(
  payload: ActionUserLoginPayload,
): ReduxActionPayload<ActionUserLoginPayload> {
  return {
    type: ActionTypes.USER_LOGIN,
    payload,
  };
}

export function actionUserLogout(): ReduxActionPayload {
  return {
    type: ActionTypes.USER_LOGOUT,
  };
}
