/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  Button,
  Card,
  Form,
  Input,
  message,
  Select,
  Switch,
  Typography,
} from 'antd';
import Table from 'antd/es/table';
import type { ColumnsType } from 'antd/es/table';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import Timezone from 'timezone-enum';
import Image from '../../components/Image';
import { RouterPaths } from '../../router/paths';
import { selectUserState } from '../../store/selectors/user';
import {
  apiAgencyUsersGet,
  apiAgencyUsersUpdate,
} from '../../services/api/agency-users';
import {
  ApiAgencyUsersGetResponse,
  ApiAgencyUsersUpdateRequestBody,
} from '../../services/api/types/agency-users';
import { ImageEntity } from '../../services/api/types/images';
import { FormEntity } from '../../services/api/types/forms';

export interface AgencyUserEditFormState {
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  phoneNumber?: string;
  activeEmailNotifications?: boolean;
  notificationEmail?: string;
  timezone?: Timezone;
}

interface DataType extends FormEntity {
  key: string;
}

function AgencyUserEditPage() {
  const navigate = useNavigate();
  const { agencyUserId } = useParams();
  const { token } = useSelector(selectUserState);
  const [deleteOpen, setDeleteOpen] = React.useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = React.useState<boolean>(false);

  const [userEditForm] = Form.useForm<AgencyUserEditFormState>();
  const [loadedAgencyUser, setLoadedAgencyUser] =
    React.useState<ApiAgencyUsersGetResponse | null>(null);

  async function loadAgencyUser(tk: string, uid: number) {
    const [response, status] = await apiAgencyUsersGet(uid, tk);
    if (status === 200 && response) {
      setLoadedAgencyUser(response);
    } else {
      message.error({ content: 'Agency not found!' });
      navigate(RouterPaths.AGENCY_USERS);
    }
  }

  React.useEffect(() => {
    if (agencyUserId && token) {
      loadAgencyUser(token, parseInt(agencyUserId, 10));
    }
  }, [agencyUserId, token]);

  const agencyUserEditSubmitCallback = React.useCallback(
    async (values: AgencyUserEditFormState) => {
      if (loadedAgencyUser && token) {
        const updateBodyRequest: ApiAgencyUsersUpdateRequestBody = {
          id: loadedAgencyUser.id,
        };
        let shouldUpdate = false;
        if (values.firstName !== loadedAgencyUser.firstName) {
          updateBodyRequest.firstName = values.firstName;
          shouldUpdate = true;
        }
        if (values.lastName !== loadedAgencyUser.lastName) {
          updateBodyRequest.lastName = values.lastName;
          shouldUpdate = true;
        }
        if (values.email !== loadedAgencyUser.email) {
          updateBodyRequest.email = values.email;
          shouldUpdate = true;
        }
        if (values.password) {
          updateBodyRequest.password = values.password;
          shouldUpdate = true;
        }
        if (values.phoneNumber !== loadedAgencyUser.phoneNumber) {
          updateBodyRequest.phoneNumber = values.phoneNumber;
          shouldUpdate = true;
        }
        if (values.notificationEmail !== loadedAgencyUser.notificationEmail) {
          updateBodyRequest.notificationEmail = values.notificationEmail;
          shouldUpdate = true;
        }
        if (
          values.activeEmailNotifications !==
          loadedAgencyUser.activeEmailNotifications
        ) {
          updateBodyRequest.activeEmailNotifications =
            values.activeEmailNotifications;
          shouldUpdate = true;
        }
        if (values.timezone !== loadedAgencyUser.timezone) {
          updateBodyRequest.timezone = values.timezone;
          shouldUpdate = true;
        }

        if (shouldUpdate) {
          const [response, status] = await apiAgencyUsersUpdate(
            updateBodyRequest,
            token,
          );
          if (status === 200) {
            message.success({ content: 'Agency updated successfully!' });
            loadAgencyUser(token, response.id);
          } else {
            message.error({ content: 'Failed agency update!' });
          }
        }
      }
    },
    [loadedAgencyUser, token],
  );

  const columns = React.useMemo<ColumnsType<DataType>>(
    () => [
      {
        key: 'id',
        title: 'ID',
        dataIndex: 'id',
        fixed: true,
        render: (v) => `${v}`,
      },
      {
        key: 'logoImage',
        title: 'Logo',
        dataIndex: 'logoImage',
        fixed: true,
        render: (v: ImageEntity) => (v?.id ? <Image id={v?.id} /> : 'No Logo'),
      },
      {
        key: 'origin',
        title: 'Origin',
        dataIndex: 'origin',
        fixed: true,
        render: (v) => `${v}`,
      },
      {
        key: 'name',
        title: 'Form Name',
        dataIndex: 'name',
        render: (v) => `${v}`,
      },
      {
        key: 'createdAt',
        title: 'Created at',
        dataIndex: 'createdAt',
        render: (v) => `${v}`,
      },
      {
        key: 'deletedAt',
        title: 'Deleted at',
        dataIndex: 'deletedAt',
        render: (v) => `${v}`,
      },
      {
        key: 'brandColor',
        title: 'Brand Color',
        dataIndex: 'brandColor',
        render: (v) => (
          <div
            style={{
              height: '32px',
              width: '32px',
              backgroundColor: `#${v}`,
              borderRadius: '50%',
            }}
          />
        ),
      },
      {
        key: 'slug',
        title: 'Slug',
        dataIndex: 'slug',
        render: (v) => `${v}`,
      },
      {
        key: 'widgetButtonText',
        title: 'Widget Button Text',
        dataIndex: 'widgetButtonText',
        render: (v) => `${v}`,
      },
      {
        key: 'widgetTitle',
        title: 'Widget Title',
        dataIndex: 'widgetTitle',
        render: (v) => `${v}`,
      },
      {
        key: 'developmentMode',
        title: 'Is In Development Mode',
        dataIndex: 'settings',
        render: (v) => `${v.developmentMode}`,
      },
    ],
    [],
  );

  if (!agencyUserId) {
    navigate(RouterPaths.AGENCY_USERS);
    return null;
  }

  return (
    <Card bordered={false} title="Single Agency">
      <Typography.Title level={2}>Agency Edit</Typography.Title>
      {/* <Button
        type="primary"
        danger
        onClick={() => {
          setDeleteOpen(true);
        }}
        style={{
          marginBottom: 20,
        }}
      >
        Delete Agency
      </Button>
      {loadedAgencyUser ? (
        <Modal
          title="Are you sure?"
          onOk={async () => {
            if (token) {
              setDeleteLoading(true);
              try {
                const [res, status] = await apiUsersDelete(
                  { userId: loadedAgencyUser.id },
                  token,
                );
                if (status === 201) {
                  message.success('Successfully deleted!');
                  navigate(RouterPaths.USERS);
                }
              } catch (e) {
                message.error('Something went wrong!');
                setDeleteLoading(false);
              }
            }
          }}
          onCancel={() => {
            setDeleteOpen(false);
          }}
          confirmLoading={deleteLoading}
          open={deleteOpen}
        />
      ) : null} */}
      {loadedAgencyUser ? (
        <Form form={userEditForm} onFinish={agencyUserEditSubmitCallback}>
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              {
                required: false,
                message: 'Please input first name!',
                max: 100,
              },
            ]}
            initialValue={loadedAgencyUser.firstName}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              { required: false, message: 'Please input last name!', max: 100 },
            ]}
            initialValue={loadedAgencyUser.lastName}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              { required: false, message: 'Please input email!', max: 255 },
            ]}
            initialValue={loadedAgencyUser.email}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Notifcation Email"
            name="notificationEmail"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              { required: false, message: 'Please input email!', max: 255 },
            ]}
            initialValue={loadedAgencyUser.notificationEmail}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Phone number"
            name="phoneNumber"
            rules={[
              {
                required: false,
                message: 'Please input phone number!',
                max: 255,
              },
            ]}
            initialValue={loadedAgencyUser.phoneNumber}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="password"
            rules={[
              { required: false, message: 'Please input password!', max: 255 },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Active Email Notifications"
            name="activeEmailNotifications"
            valuePropName="checked"
            initialValue={loadedAgencyUser.activeEmailNotifications}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label="Timezone"
            name="timezone"
            initialValue={loadedAgencyUser.timezone}
          >
            <Select>
              {Object.values(Timezone).map((tz) => (
                <Select.Option key={tz} value={tz}>
                  {tz}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update user
            </Button>
          </Form.Item>
        </Form>
      ) : (
        'Loading'
      )}
      <Typography.Title level={2}>
        Forms{' '}
        {loadedAgencyUser?.forms ? `(${loadedAgencyUser.forms.length})` : ``}
      </Typography.Title>
      <Table
        dataSource={
          (loadedAgencyUser?.forms
            ? loadedAgencyUser.forms.map((fr) => ({ ...fr, key: fr.id }))
            : []) as unknown as DataType[]
        }
        scroll={{ x: true }}
        loading={loadedAgencyUser === null}
        columns={columns}
        pagination={false}
        onRow={(record) => ({
          onClick: () => {
            navigate(`${RouterPaths.FORMS}/${record.id}`);
          },
        })}
      />
    </Card>
  );
}

export default AgencyUserEditPage;
