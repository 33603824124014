/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react';
import { Button, Card, Table, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';
import { useNavigate } from 'react-router';
import { RouterPaths } from '../../router/paths';
import { AgencyUserEntity } from '../../services/api/types/agency-users';
import { apiAgencyUsersList } from '../../services/api/agency-users';

interface DataType extends AgencyUserEntity {
  key: string;
}

const PER_PAGE_LIMIT = 10;

function AgencyUsersPage() {
  const [offset, setOffset] = React.useState(0);
  const [dataSource, setDataSource] = React.useState<
    [DataType[], number] | null
  >(null);
  const { token } = useSelector(selectUserState);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (token) {
      (async () => {
        const response = await apiAgencyUsersList(
          { offset, limit: PER_PAGE_LIMIT },
          token,
        );
        if (response) {
          if (dataSource) {
            setDataSource([
              [
                ...dataSource[0],
                ...response.agencyUsers.map((usr) => ({
                  ...usr,
                  key: usr.id.toString(),
                })),
              ],
              response.total,
            ]);
          } else {
            setDataSource([
              response.agencyUsers.map((usr) => ({
                ...usr,
                key: usr.id.toString(),
              })),
              response.total,
            ]);
          }
          setDataSource([
            response.agencyUsers.map((usr) => ({
              ...usr,
              key: usr.id.toString(),
            })),
            response.total,
          ]);
        } else {
          setDataSource([[], 0]);
        }
      })();
    }
  }, [offset]);

  const columns = React.useMemo<ColumnsType<DataType>>(
    () => [
      {
        key: 'id',
        title: 'ID',
        dataIndex: 'id',
        render: (v) => `${v}`,
        fixed: true,
      },
      {
        key: 'email',
        title: 'Email',
        dataIndex: 'email',
        fixed: true,
        render: (v) => `${v}`,
      },
      {
        key: 'numberOfForms',
        title: 'Number Of Forms',
        dataIndex: 'forms',
        render: (v) => `${v?.length}`,
      },
      {
        key: 'createdAt',
        title: 'Created at',
        dataIndex: 'createdAt',
        render: (v) => `${v}`,
      },
      {
        key: 'deletedAt',
        title: 'Deleted at',
        dataIndex: 'deletedAt',
        render: (v) => `${v}`,
      },
      {
        key: 'firstName',
        title: 'First name',
        dataIndex: 'firstName',
        render: (v) => `${v}`,
      },
      {
        key: 'lastName',
        title: 'Last name',
        dataIndex: 'lastName',
        render: (v) => `${v}`,
      },
      {
        key: 'phoneNumber',
        title: 'Phone number',
        dataIndex: 'phoneNumber',
        render: (v) => `${v}`,
      },
      {
        key: 'active',
        title: 'Active',
        dataIndex: 'active',
        render: (v) => `${v}`,
      },
      {
        key: 'notificationEmail',
        title: 'Notification Email',
        dataIndex: 'notificationEmail',
        render: (v) => `${v}`,
      },
      {
        key: 'timezone',
        title: 'Timezone',
        dataIndex: 'timezone',
        render: (v) => `${v}`,
      },
    ],
    [],
  );

  return (
    <Card bordered={false} title="Agencies">
      <div style={{ display: 'flex', width: '100%', marginBottom: 35 }}>
        <Button
          type="primary"
          onClick={() => {
            navigate(`${RouterPaths.AGENCY_USERS}/create`);
          }}
        >
          Create Agency
        </Button>
      </div>
      <Typography.Title level={2}>List</Typography.Title>
      {dataSource ? (
        <>
          <Table
            scroll={{ x: true }}
            dataSource={dataSource[0]}
            columns={columns}
            pagination={{
              total: dataSource[1],
            }}
            onRow={(record) => ({
              onClick: () => {
                navigate(`${RouterPaths.AGENCY_USERS}/${record.id}`);
              },
            })}
            onChange={(pagination) => {
              if (pagination.current) {
                setOffset(10 * (pagination.current - 1));
              }
            }}
          />
        </>
      ) : null}
    </Card>
  );
}

export default AgencyUsersPage;
