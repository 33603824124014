import { Button, Card, Form, Input, message } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import ImageInput from '../../components/ImageInput/imageInput';
import { selectUserState } from '../../store/selectors/user';
import { apiStepsGet } from '../../services/api/steps';
import { RouterPaths } from '../../router/paths';
import { ApiStepsGetResponse, InputType } from '../../services/api/types/steps';
import { apiOptionsCreate } from '../../services/api/options';

export interface CreateOptionFormState {
  value: string;
  order: number;
  resultTitle?: string;
  resultDescription?: string;
  resultMinPrice?: number;
  imageId: number;
  resultImageId?: number;
}

function OptionCreatePage() {
  const [optionCreateForm] = Form.useForm<CreateOptionFormState>();
  const { stepId } = useParams();
  const navigate = useNavigate();
  const { token } = useSelector(selectUserState);

  const [responseGetStep, setResponseGetStep] =
    React.useState<ApiStepsGetResponse | null>(null);

  async function loadFormData(tk: string, sid: number) {
    const [response, status] = await apiStepsGet(tk, sid);
    if (status === 200) {
      setResponseGetStep(response);
    } else {
      message.error({ content: 'Error loading option!' });
      navigate('/');
    }
  }

  React.useEffect(() => {
    if (token && stepId) {
      loadFormData(token, parseInt(stepId, 10));
    }
  }, [token, stepId]);

  const type = responseGetStep?.data?.inputs?.[0]?.inputType || null;

  const createStepCallback = React.useCallback(
    async (values: CreateOptionFormState) => {
      if (token && stepId) {
        const [, status] = await apiOptionsCreate(token, {
          ...values,
          stepId: parseInt(stepId, 10),
          imageId:
            responseGetStep?.data.inputs?.[0]?.inputType ===
              InputType.SINGLE_SELECT ||
            responseGetStep?.data.inputs?.[0]?.inputType ===
              InputType.MULTIPLE_SELECT
              ? values.imageId
              : null,
          order: parseInt(values.order as unknown as string, 10),
        });
        if (status !== 201) {
          message.error('Error creating option!');
        } else {
          message.success('Successfully created option');
          navigate(`${RouterPaths.STEPS}/${stepId}`);
        }
      }
    },
    [token, stepId],
  );

  if (!stepId) {
    message.error('Missing stepId in url!');
    navigate('/');
    return null;
  }

  return (
    <Card bordered={false} title="Option Create">
      {responseGetStep ? (
        <Form form={optionCreateForm} onFinish={createStepCallback}>
          <Form.Item
            label="Value"
            name="value"
            rules={[
              {
                required: true,
                message: 'Please input option value!',
                max: 512,
              },
            ]}
          >
            <Input placeholder="Yes" />
          </Form.Item>
          <Form.Item
            label="Order"
            name="order"
            rules={[
              {
                required: true,
                message: 'Please input option order!',
              },
            ]}
            initialValue={0}
          >
            <Input type="number" />
          </Form.Item>
          {type === InputType.SINGLE_SELECT ||
          type === InputType.MULTIPLE_SELECT ? (
            <ImageInput
              name="imageId"
              label="Option Image"
              userId={responseGetStep.data.form?.user?.id}
            />
          ) : null}
          <Form.Item
            label="Result Title"
            name="resultTitle"
            rules={[
              {
                required: false,
                message: 'Max length 512!',
                max: 512,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Result Description"
            name="resultDescription"
            rules={[
              {
                required: false,
                message: 'Max length 512!',
                max: 512,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Result Min Price in cents"
            name="resultMinPrice"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <ImageInput
            name="resultImageId"
            label="Result Image"
            userId={responseGetStep.data.form?.user?.id}
            required={false}
          />
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create Option
            </Button>
          </Form.Item>
        </Form>
      ) : (
        'Loading'
      )}
    </Card>
  );
}

export default OptionCreatePage;
